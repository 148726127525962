import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import findIndex from 'lodash.findindex';
import orderBy from 'lodash.orderby';
import client from '../../api/client';

export const listUserAcademies = createAsyncThunk('academies/listUserAcademies', (thunkApi) =>
  client(thunkApi).get('/v1/academies', { withCredentials: true }));

export const createAcademy = createAsyncThunk('academies/createAcademy', (createAcademyPayload, thunkApi) =>
  client(thunkApi).post('/v1/academies', createAcademyPayload, { withCredentials: true }));

export const fetchAcademySettings = createAsyncThunk('academies/fetchAcademySettings', (academyId, thunkApi) =>
  client(thunkApi).get(`/v1/academies/${academyId}/settings`, { withCredentials: true }));

export const putAcademySettings = createAsyncThunk('academies/putAcademySettings', ({ academyId, settings }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/settings`, settings, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data'} }));

export const fetchAcademyCommerce = createAsyncThunk('academies/fetchAcademyCommerce', (academyId, thunkApi) =>
  client(thunkApi).get(`/v1/academies/${academyId}/commerce`, { withCredentials: true }));

export const putAcademyProduct = createAsyncThunk('academies/putAcademyProduct', ({ academyId, product: { productId, ...rest } }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/commerce/products/${productId}`, rest, { withCredentials: true }));

export const createAcademyProduct = createAsyncThunk('academies/createAcademyProduct', ({ academyId, product }, thunkApi) => {
  return client(thunkApi).post(`/v1/academies/${academyId}/commerce/products`, product, { withCredentials: true });
});

export const deleteAcademyProduct = createAsyncThunk('academies/deleteAcademyProduct', ({ academyId, product: { productId } }, thunkApi) => {
  return client(thunkApi).delete(`/v1/academies/${academyId}/commerce/products/${productId}`, { withCredentials: true });
});

export const fetchAcademyStaff = createAsyncThunk('academies/fetchAcademyStaff', (academyId, thunkApi) =>
  client(thunkApi).get(`/v1/academies/${academyId}/staff`, { withCredentials: true }));

export const putAcademyStaff = createAsyncThunk('academies/putAcademyStaff', ({ academyId, ...rest }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/staff/members`, rest, { withCredentials: true }));

const academiesSlice = createSlice({
  name: 'academies',
  initialState: {
    createAcademyStatus: 'idle',
    fetchAcademyCommerceStatus: {},
    putAcademyProductStatus: {},
    createAcademyProductStatus: {},
    deleteAcademyProductStatus: {},
    fetchAcademySettingsStatus: {},
    fetchAcademyStaffStatus: {},
    listUserAcademiesStatus: 'idle',        
    putAcademySettingsStatus: {},
    putAcademyStaffStatus: 'idle',
    userAcademies: [],
    academyCommerce: {},    
    academySettings: {},
    availableSettings: {},    
    showCreateAcademyDialog: false,
    showAddAcademyStaffMemberDialog: false,
    addAcademyStaffMemberGroups: [],
    academyStaff: {},
  },
  reducers: {
    showCreateAcademyDialog(state) {
      state.showCreateAcademyDialog = true
    },
    hideCreateAcademyDialog(state) {
      state.showCreateAcademyDialog = false
    },
    showAddAcademyStaffMemberDialog(state, action) {
      state.showAddAcademyStaffMemberDialog = true
      state.addAcademyStaffMemberInput = action.payload
    },
    hideAddAcademyStaffMemberDialog(state) {
      state.showAddAcademyStaffMemberDialog = false
      state.addAcademyStaffMemberGroups = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(listUserAcademies.pending, (state) => {
        state.listUserAcademiesStatus = 'loading'
      })
      .addCase(listUserAcademies.rejected, (state) => {
        state.listUserAcademiesStatus = 'failed'
      })
      .addCase(listUserAcademies.fulfilled, (state, action) => {
        state.listUserAcademiesStatus = 'succeeded'
        state.userAcademies = action.payload.academies
      })
      .addCase(createAcademy.pending, (state) => {
        state.createAcademyStatus = 'loading'
      })
      .addCase(createAcademy.rejected, (state, action) => {
        state.createAcademyStatus = 'failed'
        state.createAcademyFailedStatus = action.payload.status
      })
      .addCase(createAcademy.fulfilled, (state, action) => {
        state.createAcademyStatus = 'succeeded'
        state.userAcademies = state.userAcademies.concat(action.payload)
      })
      .addCase(fetchAcademySettings.pending, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademySettingsStatus[academyId] = 'loading';
      })
      .addCase(fetchAcademySettings.rejected, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademySettingsStatus[academyId] = 'failed'
      })
      .addCase(fetchAcademySettings.fulfilled, (state, action) => {
        const { academyId, availableSettings, academySettings } = action.payload;
        state.fetchAcademySettingsStatus[academyId] = 'succeeded';        
        state.availableSettings[academyId] = availableSettings;
        state.academySettings[academyId] = academySettings;
      })
      .addCase(putAcademySettings.pending, (state, action) => {
        const { arg: academyId } = action.meta;
        state.putAcademySettingsStatus[academyId] = 'loading';
      })
      .addCase(putAcademySettings.rejected, (state, action) => {
        const { arg: academyId } = action.meta;
        state.putAcademySettingsStatus[academyId] = 'failed'
      })
      .addCase(putAcademySettings.fulfilled, (state, action) => {
        const { academyId } = action.payload;
        state.putAcademySettingsStatus[academyId] = 'succeeded';
      })
      .addCase(fetchAcademyCommerce.pending, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademyCommerceStatus[academyId] = 'loading';
      })
      .addCase(fetchAcademyCommerce.rejected, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademyCommerceStatus[academyId] = 'failed';
      })
      .addCase(fetchAcademyCommerce.fulfilled, (state, action) => {
        const { academyId, ...rest } = action.payload;
        state.fetchAcademyCommerceStatus[academyId] = 'succeeded';        
        state.academyCommerce[academyId] = { ...rest };
      })
      .addCase(putAcademyProduct.pending, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.putAcademyProductStatus[`${academyId}::${productId}`] = 'loading';
      })
      .addCase(putAcademyProduct.rejected, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.putAcademyProductStatus[`${academyId}::${productId}`] = 'failed';
      })
      .addCase(putAcademyProduct.fulfilled, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;        
        state.putAcademyProductStatus[`${academyId}::${productId}`] = 'succeeded';
        const product = state.academyCommerce[academyId].products.find((product) => product.productId === productId);
        if (product) {
          Object.assign(product, action.payload.product);
        }
      })
      .addCase(createAcademyProduct.pending, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.createAcademyProductStatus[`${academyId}::${productId}`] = 'loading';
      })
      .addCase(createAcademyProduct.rejected, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.createAcademyProductStatus[`${academyId}::${productId}`] = 'failed';
      })
      .addCase(createAcademyProduct.fulfilled, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;        
        state.createAcademyProductStatus[`${academyId}::${productId}`] = 'succeeded';
        state.academyCommerce[academyId].products.push(action.payload.product);
        state.academyCommerce[academyId].products = orderBy(state.academyCommerce[academyId].products, ['name'])        
      })
      .addCase(deleteAcademyProduct.pending, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.deleteAcademyProductStatus[`${academyId}::${productId}`] = 'loading';
      })
      .addCase(deleteAcademyProduct.rejected, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;
        state.deleteAcademyProductStatus[`${academyId}::${productId}`] = 'failed';
      })
      .addCase(deleteAcademyProduct.fulfilled, (state, action) => {
        const { arg: { academyId, product: { productId } } } = action.meta;        
        state.deleteAcademyProductStatus[`${academyId}::${productId}`] = 'succeeded';
        state.academyCommerce[academyId].products.splice(
          state.academyCommerce[academyId].products.findIndex((product) => product.productId === productId), 1
        );
      })
      .addCase(fetchAcademyStaff.pending, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademyStaffStatus[academyId] = 'loading';
      })
      .addCase(fetchAcademyStaff.rejected, (state, action) => {
        const { arg: academyId } = action.meta;
        state.fetchAcademyStaffStatus[academyId] = 'failed';
      })
      .addCase(fetchAcademyStaff.fulfilled, (state, action) => {
        const { academyId, members } = action.payload;
        state.fetchAcademyStaffStatus[academyId] = 'succeeded';
        state.academyStaff[academyId] = { members };
      })
      .addCase(putAcademyStaff.pending, (state) => {
        state.putAcademyStaffStatus = 'loading';
      })
      .addCase(putAcademyStaff.rejected, (state) => {
        state.putAcademyStaffStatus = 'failed';
      })
      .addCase(putAcademyStaff.fulfilled, (state, action) => {
        const { academyId, emailAddress, groups } = action.payload;
        state.putAcademyStaffStatus = 'succeeded';
        const isMemberInGroup = groups.reduce((cur, next) => Object.assign(cur, { [next.roleName]: true }), {});
        (state.academyStaff[academyId] || { members: { groups: [] }}).members.groups.forEach(({ roleName, members }, groupIdx) => {
          const indexInMembers = findIndex(members, { emailAddress });
          if (isMemberInGroup[roleName] && indexInMembers === -1) {
            members.push({ emailAddress });
            state.academyStaff[academyId].members.groups[groupIdx].members = orderBy(members, ['emailAddress'])
          } else if (!isMemberInGroup[roleName] && indexInMembers >= 0) {
            members.splice(indexInMembers, 1);
          }
        });     
      })
  }
})

export const {
  showCreateAcademyDialog, hideCreateAcademyDialog,
  showAddAcademyStaffMemberDialog, hideAddAcademyStaffMemberDialog,
} = academiesSlice.actions;

export default academiesSlice.reducer;
