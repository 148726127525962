import { Avatar, Button, ButtonGroup, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showCreateAcademyDialog } from './academiesSlice';

const AcademiesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userAcademies = useSelector(state => state.academies.userAcademies)
  const handleMembersClicked = (academy) => {
    navigate(`/academies/${academy.academyId}/members`);
  }
  const handleSettingsClicked = (academy) => {
    navigate(`/academies/${academy.academyId}/settings`);
  }
  const handleCommerceClicked = (academy) => {
    navigate(`/academies/${academy.academyId}/commerce`);
  }
  const handleCreateAcademyClick = () => {
    dispatch(showCreateAcademyDialog());
  }
  return (
    <div className="m-4">
      {userAcademies.length > 0 && (
        <React.Fragment>
          <Typography variant="h5">
            Academies ({userAcademies.length})
          </Typography>
          <List className="sm:w-2/3 lg:w-1/2">
            {userAcademies.map((academy) => (
              <ListItem key={academy.academyId} secondaryAction={
                <ButtonGroup variant="text" edge="end">
                  <Button onClick={() => handleMembersClicked(academy)}>Members</Button>
                  {academy && academy.userRoles && academy.userRoles.indexOf("Administrator") >= 0 && <Button onClick={() => handleCommerceClicked(academy)}>Commerce</Button>}
                  {academy && academy.userRoles && academy.userRoles.indexOf("Administrator") >= 0 && <Button onClick={() => handleSettingsClicked(academy)}>Settings</Button>}
                </ButtonGroup>
              }>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={academy.academyName} secondary={academy.userRoles.join('|')} />
              </ListItem>
            ))}
          </List>
          <Button variant="outlined" onClick={() => handleCreateAcademyClick()}>Create Academy/Club</Button>
        </React.Fragment>
      )}      
    </div>
  )
}

export default AcademiesPage;