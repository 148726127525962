import { ArrowBack, Edit } from "@mui/icons-material";
import { Alert, Button, Divider, IconButton, Typography } from "@mui/material";
import find from "lodash.find";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAcademyStaff, hideAddAcademyStaffMemberDialog, showAddAcademyStaffMemberDialog } from "./academiesSlice";

const AcademyMembersPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { academyId } = useParams();
  const userAcademies = useSelector(state => state.academies.userAcademies);
  const fetchAcademyStaffStatus = useSelector(state => state.academies.fetchAcademyStaffStatus);
  const academyStaff = useSelector(state => state.academies.academyStaff);
  const putAcademyStaffStatus = useSelector(state => state.academies.putAcademyStaffStatus);

  const academy = find(userAcademies, (academy) => academy.academyId === academyId);
  const staff = academyStaff[academyId];
  
  useEffect(() => {
    if (!fetchAcademyStaffStatus[academyId] || fetchAcademyStaffStatus[academyId] === 'idle') {
      dispatch(fetchAcademyStaff(academyId));
    }
  }, [fetchAcademyStaffStatus, academyId, dispatch]);

  useEffect(() => {
    if (putAcademyStaffStatus === 'succeeded') {
      dispatch(hideAddAcademyStaffMemberDialog());
    }
  }, [putAcademyStaffStatus, dispatch]);

  const handleAddAcademyStaffMemberClicked = () => {
    const { groups } = staff.members;
    dispatch(showAddAcademyStaffMemberDialog({ academyId, academyName: academy.academyName, groups }));
  };

  const handleEditStaffMemberClicked = ({ emailAddress }) => {
    const { groups } = staff.members;
    dispatch(showAddAcademyStaffMemberDialog({ academyId, academyName: academy.academyName, groups, emailAddress }));
  };

  const staffMember = ({ emailAddress }) => (
    <li key={emailAddress} className={'group hover:bg-orange-100'}>
      <span>{emailAddress}</span>
      <IconButton
        size="small"
        aria-label="Edit/remove staff member"
        onClick={() => handleEditStaffMemberClicked({ emailAddress })}
        color="inherit"
        classes={{root: "lg:invisible group-hover:visible"}}
      >
        <Edit />
      </IconButton>
    </li>
  )
  const staffMemberGroup = ({ label, members }) => (
    <div key={label} className="mb-4">
      <Divider textAlign="left">{label} ({members.length})</Divider>
      <ul className="group">{members.map(staffMember)}</ul>
    </div>
  );
  const staffMemberGroups = academy && fetchAcademyStaffStatus[academyId] === 'succeeded' && staff.members.groups.map(staffMemberGroup);

  return (
    <React.Fragment>
      {fetchAcademyStaffStatus[academyId] === 'failed' && <Alert severity="error">
        Failed to fetch staff members for academy {academy.academyName}. Check with an administrator of the academy and verify that you are allowed to configure settings.
      </Alert>}
      <div className="m-4">
        <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate("/academies")}>
          My Academies
        </Button>
        <Typography variant="h5">{(academy || { academyName: 'Academy' }).academyName} Staff</Typography>
        <br />
        <Button variant="outlined" onClick={handleAddAcademyStaffMemberClicked}>Add Staff Member</Button>
        <br />
        <br />
        {staffMemberGroups}
      </div>      
    </React.Fragment>
  );
};

export default AcademyMembersPage;