import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { showLogInDialog } from '../session/sessionSlice'
import { useNavigate } from 'react-router-dom'

const UserAvatarMenu = ({ handleLogOut }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sessionInfo = useSelector(state => state.session)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  };

  const handleAcademiesClicked = () => {
    navigate("/academies");
    handleCloseUserMenu();
  }
  
  const handleLogOutClicked = () => {
    handleLogOut(dispatch)
    handleCloseUserMenu()
  }

  const handleLogInClicked = () => {
    dispatch(showLogInDialog())
  }

  const settings = [
    { text: 'Account', action: handleCloseUserMenu },
    { text: 'My Academies', action: handleAcademiesClicked },
    { text: 'Logout', action: handleLogOutClicked }
  ]

  return (
    <div>
      {sessionInfo.isLoggedIn ? (
        <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting.text} onClick={setting.action}>
              <Typography textAlign="center">{setting.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      ) : (
        <Button color="inherit" onClick={handleLogInClicked}>Log in</Button>
      )}
    </div>
  )
}

export default UserAvatarMenu