import { ArrowBack } from '@mui/icons-material';
import { Alert, Button, Divider, Unstable_Grid2 as Grid, Typography, Icon } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import find from 'lodash.find'
import { fetchAcademySettings, putAcademySettings } from './academiesSlice';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { encrypt } from '../../utils/encrypt';
import get from 'lodash.get';

const AcademySettingsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { academyId } = useParams();
  const userAcademies = useSelector(state => state.academies.userAcademies);
  const fetchAcademySettingsStatus = useSelector(state => state.academies.fetchAcademySettingsStatus);
  const academySettings = useSelector(state => state.academies.academySettings)
  const availableSettings = useSelector(state => state.academies.availableSettings);

  const [showingSecret, setShowingSecret] = useState({});

  const academy = find(userAcademies, (academy) => academy.academyId === academyId);
  
  useEffect(() => {
    if (academy) {
      dispatch(fetchAcademySettings(academy.academyId));
    }
  }, [academy, dispatch]);

  const settingName = (category, categorySetting) => `${category}.${categorySetting.key}`;

  const initialValues = (availableSettings[academyId] || []).reduce((prev, curCategories) => Object.assign(prev, {
    [curCategories.category]: curCategories.settings.reduce((prev, curSettings) => Object.assign(prev, {
      [curSettings.key]: get(academySettings[academyId], `${curCategories.category}.${curSettings.key}`)
    }), {})}), {}
  );

  const secretSetting = (category, categorySetting) => {
    const name = settingName(category, categorySetting);
    const showSecret = () => {
      setShowingSecret((showingSecret) => {
        return Object.assign({}, showingSecret, { [name]: true } );
      });
    };
    if (showingSecret[name]) {
      return <Field component={TextField} name={name} type="password" margin="normal" fullWidth />;
    } else {
      return <span><KeyIcon /> The value of this setting is secret. To provide a new value,<Button variant="text" onClick={showSecret}>click here</Button></span>;
    }
  }

  const showSetting = (values, categorySetting) => {
    if (categorySetting.dependsOnSetting) {
      return get(values, categorySetting.dependsOnSetting) === categorySetting.dependsOnValue;
    }
    return true;
  }

  return (
    <React.Fragment>
      {fetchAcademySettingsStatus[academyId] === 'failed' && <Alert severity="error">
        Failed to fetch settings for academy {academy.academyName}. Check with an administrator of the academy and verify that you are allowed to configure settings.
      </Alert>}
      <div className="m-4">
        <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate("/academies")}>
          My Academies
        </Button>
        {fetchAcademySettingsStatus[academyId] === 'succeeded' && (
          <Formik
            initialValues={initialValues}
            validate={(values => {
              const errors = {}
              return errors
            })}
            onSubmit = {(values, { setSubmitting }) => {
              const settings = new FormData();
              availableSettings[academyId].forEach(categoryData => {
                categoryData.settings.forEach(categorySetting => {
                  let value = values[categoryData.category][categorySetting.key];
                  if (value && categorySetting.secret === true) {
                    value = encrypt(value);
                  }
                  if (value !== null && value !== undefined) {
                    settings.append(`${categoryData.category}.${categorySetting.key}`, value);
                  }
                })
              });
              dispatch(putAcademySettings({ academyId, settings })).finally(() => setSubmitting(false))
            }}
          >
            {({ values, setFieldValue, submitForm }) => (
              <Form>
                {(availableSettings[academyId] || []).map(categoryData => (
                  <React.Fragment key={categoryData.category}>
                    <Divider textAlign="left">{categoryData.categoryLabel}</Divider>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                      {categoryData.settings.map(categorySetting => (
                        showSetting(values, categorySetting) && <React.Fragment key={`${categoryData.category}.${categorySetting.key}`}>
                          <Grid xs={12} sm={6} md={3}>
                            <Typography variant="body1">{categorySetting.label}</Typography>
                          </Grid>
                          <Grid xs={12} sm={6} md={9}>
                            {categorySetting.type === 'text' && categorySetting.secret !== true && <Field component={TextField} name={`${categoryData.category}.${categorySetting.key}`} margin="normal" fullWidth />}
                            {categorySetting.type === 'text' && categorySetting.secret === true && secretSetting(categoryData.category, categorySetting)}
                            {categorySetting.type === 'long-text' && <Field component={TextField} name={`${categoryData.category}.${categorySetting.key}`} margin="normal" fullWidth multiline/>}
                            {categorySetting.type === 'file' && (
                              <input name={`${categoryData.category}.${categorySetting.key}`} type="file" onChange={(event) => {
                                setFieldValue(`${categoryData.category}.${categorySetting.key}`, event.currentTarget.files[0]);
                              }} />
                            )}
                            {categorySetting.type === 'select' && (
                              <div role="group">
                                {categorySetting.choices.map(({ label, value }) => (
                                  <label key={value} className={'block'}>
                                    <Field type="radio" name={`${categoryData.category}.${categorySetting.key}`} value={value} />
                                    {` ${label}`}
                                  </label>
                                ))}
                              </div>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>                    
                  </React.Fragment>
                ))}
                <br />
                <Button variant='outlined' onClick={submitForm}>Save</Button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </React.Fragment>
  );
}

export default AcademySettingsPage;