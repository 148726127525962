import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { gapi } from 'gapi-script';
import { GoogleLogin } from '@leecheuk/react-google-login';
import './App.css';
import AppBar from './app/AppBar';
import LogInDialog from './features/session/LogInDialog';
import { clearSession, deleteSession, putSession } from './features/session/sessionSlice';
import RegisterUserDialog from './features/users/RegisterUserDialog';
import NoAcademiesAlert from './features/academies/NoAcademiesAlert';
import CreateAcademyDialog from './features/academies/CreateAcademyDialog';
import AcademiesPage from './features/academies/AcademiesPage';
import AcademySettingsPage from './features/academies/AcademySettingsPage';
import TournamentsPage from './features/tournaments/TournamentsPage';
import CreateTournamentDialog from './features/tournaments/CreateTournamentDialog';
import ManageTournamentsPage from './features/tournaments/ManageTournamentPage';
import EditTournamentEntrySettingsDialog from './features/tournaments/EditTournamentEntrySettingsDialog';
import ManageTournamentSectionsDialog from './features/tournaments/ManageTournamentSectionsDialog';
import ViewTournamentEntryDialog from './features/tournaments/ViewTournamentEntryDialog';
import CustomizeTournamentEntryDialog from './features/tournaments/CustomizeTournamentEntryDialog';
import AddTournamentEntryDialog from './features/tournaments/AddTournamentEntryDialog';
import MoveEntryToSectionDialog from './features/tournaments/MoveEntryToSectionDialog';
import DashboardPage from './features/dashboard/DashboardPage';
import LuckyChessPage from './features/fun/LuckyChessPage';
import AcademyMembersPage from './features/academies/AcademyMembersPage';
import AddAcademyStaffMemberDialog from './features/academies/AddAcademyStaffMemberDialog';
import ExportTournamentDialog from './features/tournaments/ExportTournamentDialog';
import ImportTournamentResourceDialog from './features/tournaments/ImportTournamentResourceDialog';
import ViewEntryAttributesDialog from './features/tournaments/ViewEntryAttributesDialog';
import AcademyCommercePage from './features/academies/AcademyCommercePage';

const clientId = '372353591792-1lbrtm9it8f21r6d2kvijdm6c50d7t75.apps.googleusercontent.com'

function App() {
  const dispatch = useDispatch()
  const sessionId = useSelector(state => state.session.sessionId)
  const sessionStatus = useSelector(state => state.session.status);

  function handleCredentialSuccess(response) {
    const { tokenObj: { id_token } } = response
    dispatch(putSession({ id_token }))
  }

  function handleCredentialFailure(err) {
    console.log('failed:', err);
  }

  function handleLogOut(dispatch) {
    const auth2 = gapi.auth2.getAuthInstance()
    auth2.signOut().then(() => {
      if (sessionId) {
        dispatch(deleteSession())
      }
      dispatch(clearSession())
    })
  }
  
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({ clientId: clientId, scope: '' });
    };
    gapi.load('client:auth2', initClient);
  });

  const pageOrDashboard = (pageFn) => sessionStatus === 'idle' ? <DashboardPage /> : pageFn();
  const academiesPage = pageOrDashboard(() => <AcademiesPage />);
  const academyMembersPage = pageOrDashboard(() => <AcademyMembersPage />);
  const academySettingsPage = pageOrDashboard(() => <AcademySettingsPage />);
  const academyCommercePage = pageOrDashboard(() => <AcademyCommercePage />);
  const manageTournamentsPage = pageOrDashboard(() => <ManageTournamentsPage />)
  const tournamentsPage = pageOrDashboard(() => <TournamentsPage />);
  const luckyChessPage = <LuckyChessPage />;

  return (
    <div className="App">
      <BrowserRouter>
        <AppBar handleLogOut={handleLogOut}/>
        
        <LogInDialog clientId={clientId} handleCredentialSuccess={handleCredentialSuccess} handleCredentialFailure={handleCredentialFailure} handleLogOut={handleLogOut}/>
        <RegisterUserDialog handleLogOut={handleLogOut}/>
        
        <CreateAcademyDialog />
        <AddAcademyStaffMemberDialog />
        
        <CreateTournamentDialog />
        <EditTournamentEntrySettingsDialog />
        <ManageTournamentSectionsDialog />
        <ViewTournamentEntryDialog />
        <CustomizeTournamentEntryDialog />
        <AddTournamentEntryDialog />
        <MoveEntryToSectionDialog />
        <ExportTournamentDialog />
        <ImportTournamentResourceDialog />
        <ViewEntryAttributesDialog />

        <NoAcademiesAlert />        
      
        <Routes>
          <Route path="/" element={(
              <React.Fragment>
                <DashboardPage />                
              </React.Fragment>
          )} />
          <Route path="academies/" element={academiesPage} />
          <Route path="academies/:academyId/members" element={academyMembersPage} />
          <Route path="academies/:academyId/settings" element={academySettingsPage} />
          <Route path="academies/:academyId/commerce" element={academyCommercePage} />
          <Route path="tournaments/" element={tournamentsPage} />
          <Route path="tournaments/:academyId/:tournamentId/manage" element={manageTournamentsPage} />
          <Route path="lucky-chess" element={luckyChessPage} />
        </Routes>
      </BrowserRouter>

      {/* Invisible Log in with Google button so that auto-signin kicks in on page load */}
      <GoogleLogin
          className='invisible'
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={handleCredentialSuccess}
          onFailure={handleCredentialFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
    </div>
  );
}

export default App;
