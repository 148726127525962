import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { set } from "lodash";
import orderBy from "lodash.orderby";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTournamentEntry, hideAddTournamentEntryDialog } from "./tournamentsSlice";
import { Spinner } from "../../components/Spinner";

const AddTournamentEntryDialog = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const showAddTournamentEntryDialog = useSelector(state => state.tournaments.showAddTournamentEntryDialog);
  const tournament = useSelector(state => state.tournaments.tournamentToEdit);
  const tournamentEntries = useSelector(state => state.tournaments.tournamentEntries);
  const createTournamentEntryStatus = useSelector(state => state.tournaments.createTournamentEntryStatus);

  useEffect(() => {
    if (showAddTournamentEntryDialog && tournament) {
      let entryPropertyNames = tournament.entryProperties;
      if (!entryPropertyNames) {
        const entriesKey = `${tournament.academyId}::${tournament.tournamentId}`;
        if (tournamentEntries && tournamentEntries[entriesKey] && tournamentEntries[entriesKey].length) {
          const tournamentEntry = tournamentEntries[entriesKey][0];
          entryPropertyNames = Object.keys(tournamentEntry.properties);
        }
      }      
      if (entryPropertyNames) {
        let initialSettings = [];
        initialSettings.push.apply(initialSettings, entryPropertyNames.map((propertyKey) => ({
          settingName: propertyKey,
          settingPath: `properties.${propertyKey.endsWith('.') ? propertyKey.substring(0, propertyKey.length-1) : propertyKey}`,
          settingValue: '',
        })));
        initialSettings = orderBy(initialSettings, 'settingName');
        setSettings(initialSettings);
      }
    }
  }, [showAddTournamentEntryDialog, tournament, tournamentEntries]);

  const onSettingChanged = (settingName) => (event) => {
    const newSettings = settings.map((setting) => {
      if (setting.settingName === settingName) {
        return { ...setting, settingValue: event.target.value };
      }
      return setting;
    })
    setSettings(newSettings);
  };

  const handleAddClicked = () => {
    const { academyId, tournamentId } = tournament;
    const entry = settings.reduce((prev, cur) => {
      const { settingPath, settingValue } = cur;
      set(prev, settingPath, settingValue);
      return prev;
    }, { properties: {}, orderContact: {}, orderMetadata: {} });
    dispatch(createTournamentEntry({ academyId, tournamentId, entry }));
  }

  return (
    <Dialog open={!!showAddTournamentEntryDialog}>
      <DialogTitle>Add Entry</DialogTitle>
      <DialogContent> 
        {settings.map(({ settingName, settingPath, settingValue }) => (
          <React.Fragment key={settingPath}>
            <TextField label={settingName} variant="outlined" value={settingValue} onChange={onSettingChanged(settingName)}/>
          </React.Fragment>
        ))}
        <Spinner thunks={createTournamentEntry} errorMessage={'Failed to add tournament entry'} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideAddTournamentEntryDialog())}>Cancel</Button>
        <Button disabled={createTournamentEntryStatus === 'loading'} onClick={handleAddClicked}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTournamentEntryDialog;