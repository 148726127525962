import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { createAcademy, hideCreateAcademyDialog } from './academiesSlice';

const CreateAcademyDialog = () => {
  const dispatch = useDispatch()

  const show = useSelector(state => state.academies.showCreateAcademyDialog)
  const createAcademyFailedStatus = useSelector(state => state.academies.createAcademyFailedStatus)
  const createAcademyStatus = useSelector(state => state.academies.createAcademyStatus)

  useEffect(() => {
    if (createAcademyStatus === 'succeeded') {
      dispatch(hideCreateAcademyDialog())
    }
  }, [createAcademyStatus, dispatch])

  const handleCancel = () => {
    dispatch(hideCreateAcademyDialog())
  }

  return (
    <Dialog open={show}>
      <DialogTitle>Create Academy</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: ''
          }}
          validate={(values => {
            const errors = {}
            if (!values.name) {
              errors.name = 'Required'
            }
            return errors
          })}
          onSubmit = {(values, { setSubmitting }) => {
            const { name } = values
            dispatch(createAcademy({ name })).finally(() => setSubmitting(false))
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={TextField}
                name="name"
                label="Name"
                margin="normal"
              />
              <br />
              { createAcademyStatus === 'failed' && createAcademyFailedStatus === 409 && (
                <Alert severity="error">An academy with the specified name already exists. If you know an individual who is a staff member of the academy or club, you should contact them and request that they add you to the staff.</Alert>
              )}
              { createAcademyStatus === 'failed' && createAcademyFailedStatus !== 409 && (
                <Alert severity="error">Academy creation failed. Please reach out to technical support.</Alert>
              )}
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={submitForm}>Create</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>      
    </Dialog>
  )
}

export default CreateAcademyDialog