import currency from 'currency.js';
import { ArrowBack } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, ButtonGroup, FormControl, InputLabel, List, ListItem, ListItemText, OutlinedInput, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { createAcademyProduct, deleteAcademyProduct, fetchAcademyCommerce, putAcademyProduct } from "./academiesSlice";
import React, { useEffect, useState } from "react";
import { set } from 'lodash';
import { useImmer } from "use-immer";
import ErrorAlert from '../../components/ErrorAlert';

const productPriceLabels = {
  memberPrice: 'Members',
  nonMemberPrice: 'Non-Members',
};

const currencies = {
  usd: value => currency(value, { symbol: "$", precision: 2 }),
};

const VALID_AMOUNT_PATTERN = /^\d+(\.\d\d)?$/;

const AcademyPricesPage = () => {
  const { academyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editingProducts, setEditingProducts] = useImmer({});
  const [newProducts, setNewProducts] = useImmer([]);
  const [newProductsCount, setNewProductsCount] = useState(0);

  const academyCommerce = useSelector(state => state.academies.academyCommerce);
  const fetchAcademyCommerceStatus = useSelector(state => state.academies.fetchAcademyCommerceStatus);
  const putAcademyProductStatus = useSelector(state => state.academies.putAcademyProductStatus);
  const deleteAcademyProductStatus = useSelector(state => state.academies.deleteAcademyProductStatus);

  useEffect(() => {
    if (fetchAcademyCommerceStatus[academyId] === undefined) {
      dispatch(fetchAcademyCommerce(academyId));
    }
  }, [dispatch, academyId, fetchAcademyCommerceStatus]);

  const onEditProductClicked = (product) => () => {
    setEditingProducts((draft) => Object.assign(draft, {[product.productId]: product}));
  };

  const onCancelEditingProductClicked = ({ productId }) => () => {
    if (productId.split(':')[0] === '_new') {
      setNewProducts((draft) => {
        draft.splice(draft.findIndex((product) => product.productId === productId), 1);
      });
    }
    setEditingProducts((draft) => Object.assign(draft, {[productId]: null}));
  }

  const onProductAttributeChanged = ({ productId, attribute }) => (event) => {
    setEditingProducts((draft) => {
      set(draft[productId], attribute, event.target.value);
    })
  }

  const onSaveProductClicked = ({ productId }) => () => {
    const createNew = productId.split(':')[0] === '_new';
    const thunk = createNew ? createAcademyProduct : putAcademyProduct;
    const productToSave = { ...editingProducts[productId] };
    productToSave.data = { prices: {} };
    Object.keys(editingProducts[productId].data.prices).forEach((priceType) => {
      productToSave.data.prices[priceType] = {
        amount: parseFloat(editingProducts[productId].data.prices[priceType].amount)
      };
    });
    dispatch(thunk({ academyId, product: productToSave })).then(({ error }) => {
      if (!error) {
        setEditingProducts((draft) => Object.assign(draft, {[productId]: null}));
        if (createNew) {
          setNewProducts((draft) => {
            draft.splice(draft.findIndex((product) => product.productId === productId), 1);
          });
        }
      }
    });
  }

  const onDeleteProductClicked = (product) => () => {
    if (window.confirm(`Are you SURE you want to delete product ${product.name}?`)) {
      dispatch(deleteAcademyProduct({ academyId, product }));
    }
  };

  const productListItem = (product) => {
    if (editingProducts[product.productId]) {
      return editProductListItem(product);
    } else {
      return showProductListItem(product);
    }
  }

  const showProductListItem = (product) => {
    const { productId, name, description, data } = product;
    const productPriceItem = (priceType) => {
      const priceLabel = productPriceLabels[priceType];
      const { amount } = data.prices[priceType];
      const currencyFn = currencies[academyCommerce.currency] || currencies.usd;
      const currencyFormatted = currencyFn(amount).format();      
      return (
        <Stack>
          <Typography variant="body1" typography={{ fontWeight: 'bold' }} className='whitespace-nowrap'>{priceLabel}</Typography>
          <Typography variant="body1">{currencyFormatted}</Typography>
        </Stack>        
      );
    }
    const actions = (
      <ButtonGroup variant="text" edge="end">
        <Button onClick={onEditProductClicked(product)}>Edit</Button>
        <Button onClick={onDeleteProductClicked(product)}>Delete</Button>
      </ButtonGroup>
    );
    return (
      <ListItem key={productId} secondaryAction={actions}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 2fr', width: '100%' }}>
          <Box sx={{ gridRow: '1' }}>
            <ListItemText primary={name} secondary={description}/>
          </Box>
          <Box sx={{ gridRow: '1' }}>
            <Stack direction="row" spacing={1}>
              {productPriceItem('memberPrice')}
              {productPriceItem('nonMemberPrice')}
            </Stack>
          </Box>
        </Box>        
      </ListItem>
    );
  };

  const editProductListItem = (product) => {
    const { productId } = product;
    const productPriceItem = (priceType) => {
      const priceLabel = productPriceLabels[priceType];
      return (
        <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
          <InputLabel htmlFor={`${productId}-price-${priceType}`}>{priceLabel} Price</InputLabel>
            <OutlinedInput
              id={`${productId}-price-${priceType}`}
              type="text"
              value={editingProducts[productId].data.prices[priceType].amount}
              onChange={onProductAttributeChanged({ productId, attribute: `data.prices.${priceType}.amount` })}
              label={priceLabel}
              error={!VALID_AMOUNT_PATTERN.test(editingProducts[productId].data.prices[priceType].amount)}
            />
        </FormControl>  
      );
    }
    const putAcademyProductIndex = `${academyId}::${productId}`;
    const updatingProduct = putAcademyProductStatus[putAcademyProductIndex] === 'loading';
    const actions = (
      <ButtonGroup variant="text" edge="end">
        <Button disabled={updatingProduct} onClick={onCancelEditingProductClicked(product)}>Cancel</Button>
        <Button disabled={updatingProduct} onClick={onSaveProductClicked(product)}>Save</Button>        
      </ButtonGroup>
    );    
    return (
      <ListItem key={productId} secondaryAction={actions} className='border-solid border border-black'>
        <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 2fr', width: '100%' }}>
          <Box sx={{ gridRow: '1' }}>
            <Stack direction="column">
              <FormControl sx={{ m: 1, width: '75%' }} variant="outlined">
                <InputLabel htmlFor={`${productId}-name`}>Product Name</InputLabel>              
                  <OutlinedInput
                    id={`${productId}-name`}
                    type="text"
                    value={editingProducts[productId].name}
                    onChange={onProductAttributeChanged({ productId, attribute: 'name' })}
                    label="Product Name"
                  />
              </FormControl>
              <FormControl sx={{ m: 1, width: '75%' }} variant="outlined">
                <InputLabel htmlFor={`${productId}-description`}>Product Description</InputLabel>              
                  <OutlinedInput
                    id={`${productId}-description`}
                    type="text"
                    value={editingProducts[productId].description}
                    onChange={onProductAttributeChanged({ productId, attribute: 'description' })}
                    label="Product Description"
                  />
              </FormControl>
            </Stack>
          </Box>
          <Box sx={{ gridRow: '1' }}>
            <Stack direction="column">
              {productPriceItem('memberPrice')}
              {productPriceItem('nonMemberPrice')}
            </Stack>
          </Box>
          <Box sx={{ gridRow: '2', gridColumn: 'span 2' }}>
            <ErrorAlert
              message={'Failed to update product. Please reach out to technical support'}
              thunks={{ thunk: putAcademyProduct, index: putAcademyProductIndex }}
            />
          </Box>
        </Box>
      </ListItem>
    );
  };

  const handleCreateProductClick = () => {
    const productId = `_new:${newProductsCount}`;
    const name = 'New Product';
    const description = '';
    const data = { prices: {} };
    Object.keys(productPriceLabels).forEach((priceType) => {
      data.prices[priceType] = { amount: 0 };
    });
    const newProduct = { productId, name, description, data };
    setEditingProducts((draft) => Object.assign(draft, {[productId]: { productId, name, description, data }}));
    setNewProducts((draft) => {
      draft.push(newProduct);
    });
    setNewProductsCount((count) => count + 1);
  };

  return (
    <div className="m-4">
      <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate("/academies")}>
        My Academies
      </Button>
      <Spinner
        thunks={{ thunk: fetchAcademyCommerce, index: academyId }}
        errorMessage={'Failed to fetch academy commerce'}
        componentFn={() => <React.Fragment>
          <Typography variant="h5">
            Products
          </Typography>
          {academyCommerce[academyId].commerceType === 'COMMERCE_INTERNAL' && <React.Fragment>
            {academyCommerce[academyId].products.map(({ productId }) => (
              <ErrorAlert
                key={productId}
                message={'Failed to delete product. Please reach out to technical support'}
                thunks={{ thunk: deleteAcademyProduct, index: `${academyId}::${productId}` }}
              />
            ))}
            <List className="sm:w-3/4">
              {academyCommerce[academyId].products.map((product) => productListItem(product))}              
              {newProducts.map((product) => editProductListItem(product))}              
            </List>
            <Button variant="outlined" onClick={() => handleCreateProductClick()}>Create Product</Button>
          </React.Fragment>}
          {academyCommerce[academyId].commerceType !== 'COMMERCE_INTERNAL' && (
            <Alert severity="info">
              <AlertTitle>No Products to Display</AlertTitle>
              Products for this academy are managed outside of Your Chess Academy.              
            </Alert>
          )}
        </React.Fragment>
      }/>
    </div>
  );
}

export default AcademyPricesPage;