import { Typography } from "@mui/material";
import { setNestedObjectValues } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const choiceLabels = {
  pawn: 'Pawn',
  knight: 'Knight',
  bishop: 'Bishop',
  rook: 'Rook',
  queen: 'Queen',
  king: 'King',
};
const defaultChoices = ['pawn', 'knight', 'bishop', 'rook', 'queen', 'king'];
const spinIntervalPeriod = 80;

const LuckyChessSpinner = ({ label, spinnerIndex, choices = defaultChoices, firstMoveChoices = defaultChoices }) => {
  const activeSpinnerIndex = useSelector(state => state.fun.luckyChessActiveSpinnerIndex)  
  const luckyChessFirstMove = useSelector(state => state.fun.luckyChessFirstMove)
  let [choice, setChoice] = useState(null);
  let [spinInterval, setSpinInterval] = useState(null);

  const isSpinning = activeSpinnerIndex === spinnerIndex;

  useEffect(() => {    
    setSpinInterval((spinInterval) => {
      if (isSpinning) {
        if (spinInterval !== null) {
          clearInterval(spinInterval);
        }
        return setInterval(() => {
          setChoice((choice) => {
            if (!isSpinning) return choice;
            let newChoiceIdx = -1;
            let activeChoices = luckyChessFirstMove ? firstMoveChoices : choices;
            while (true) {
              newChoiceIdx = Math.floor(Math.random() * (activeChoices.length));
              if (activeChoices[newChoiceIdx] !== choice || activeChoices.length === 1) return activeChoices[newChoiceIdx];
            }        
          })
        }, spinIntervalPeriod);
      } else {
        clearInterval(spinInterval);
        return null;
      }
    });
  }, [isSpinning, choices, luckyChessFirstMove, firstMoveChoices]);

  useEffect(() => {
    if (activeSpinnerIndex > 0 && activeSpinnerIndex < spinnerIndex) {
      setChoice(null);
    }
  }, [activeSpinnerIndex, spinnerIndex]);

  return (
    <div className="p-4 m-4 border-black border-2">
      <Typography variant="h4">{label}:</Typography>
      <div className={`m-4 text-7xl font-bold text-center ${isSpinning ? 'text-slate-400' : 'text-black'}`}>
        {choiceLabels[choice] || '---'}
      </div>
    </div>
  );
};

export default LuckyChessSpinner;