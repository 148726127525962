import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { hideAddAcademyStaffMemberDialog, putAcademyStaff } from './academiesSlice';

const AddAcademyStaffMemberDialog = () => {
  const dispatch = useDispatch()

  const show = useSelector(state => state.academies.showAddAcademyStaffMemberDialog)
  const putAcademyStaffStatus = useSelector(state => state.academies.putAcademyStaffStatus)
  const addAcademyStaffMemberInput = useSelector(state => state.academies.addAcademyStaffMemberInput)

  const [initialValues, setInitialValues] = useState(null);

  const isEditing = addAcademyStaffMemberInput && addAcademyStaffMemberInput.emailAddress;

  useEffect(() => {
    let initialValues = null;
    if (show) {
      initialValues = {};
      if (isEditing) {
        initialValues.emailAddress = addAcademyStaffMemberInput.emailAddress;
        addAcademyStaffMemberInput.groups.forEach(({ roleName, members }) => {
          initialValues[`role:${roleName}`] = members
            .filter(({ emailAddress }) => emailAddress === addAcademyStaffMemberInput.emailAddress)
            .map(() => roleName);
        });
      } else {
        initialValues.emailAddress = '';
        addAcademyStaffMemberInput.groups.forEach(({ roleName }) => {
          initialValues[`role:${roleName}`] = [];
        });
      }
    }
    setInitialValues(initialValues);
  }, [show, isEditing, addAcademyStaffMemberInput, dispatch])

  const handleCancel = () => {
    dispatch(hideAddAcademyStaffMemberDialog())
  }

  return (
    <Dialog open={show}>
      <DialogTitle>{isEditing ? 'Update Staff Member' : 'Add Staff Member'}</DialogTitle>
      <DialogContent>
        {initialValues && (<Formik
          initialValues={initialValues}
          validate={(values => {
            const errors = {}
            if (!values.emailAddress) {
              errors.emailAddress = 'Required'
            }
            return errors
          })}
          onSubmit = {(values, { setSubmitting }) => {
            const { emailAddress } = values;
            const { academyId, academyName } = addAcademyStaffMemberInput;
            const groups = Object.keys(values)
              .filter(key => key.indexOf('role:') === 0 && values[key].length === 1)
              .map(key => ({ roleName: key.split(':')[1] }));
            if (isEditing && groups.length === 0) {
              if (!window.confirm(`${emailAddress} will be removed from the ${academyName} staff. Are you sure you want to continue?`)) {
                setSubmitting(false);
                return;
              }
            }
            dispatch(putAcademyStaff({ academyId, emailAddress, groups }))
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ submitForm }) => (
            <Form>
              { putAcademyStaffStatus === 'failed' && (
                <Alert severity="error">Failed to add staff member. Please reach out to technical support.</Alert>
              )}
              <Field
                component={TextField}
                name="emailAddress"
                label="Email Address"
                margin="normal"
                disabled={!!isEditing}
              />
              <br />
              <Divider textAlign="left">Roles</Divider>
              {addAcademyStaffMemberInput.groups.map(({ roleName, label }) => (
                <div key={roleName}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name={`role:${roleName}`}
                    value={roleName}
                    Label={{ label }}
                  />
                </div>
              ))}

              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={submitForm}>{isEditing ? 'Update' : 'Add'}</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>)}
      </DialogContent>      
    </Dialog>
  )
}

export default AddAcademyStaffMemberDialog