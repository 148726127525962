import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import orderBy from "lodash.orderby";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMoveEntryToSectionDialog, putTournamentEntryCustomization } from "./tournamentsSlice";

const MoveEntryToSectionDialog = () => {
  const dispatch = useDispatch();
  const showMoveEntryToSectionDialog = useSelector(state => state.tournaments.showMoveEntryToSectionDialog);
  const entry = useSelector(state => state.tournaments.tournamentEntryToCustomize);

  const handleSectionClicked = (sectionName) => () => {
    const { academyId, tournamentId } = entry.tournament;
    const { entryId, customization: entryCustomization } = entry.entry;
    const customization = { ...entryCustomization, section: sectionName };
    dispatch(putTournamentEntryCustomization({ academyId, tournamentId, entryId, customization })).then(() => {
      dispatch(hideMoveEntryToSectionDialog());
    });    
  }

  return (
    <Dialog open={!!showMoveEntryToSectionDialog}>
      <DialogTitle>Assign Entry</DialogTitle>
      <DialogContent>
        {entry && entry.tournament.sections && entry.tournament.sections.map(({ name }, idx) => (
          <React.Fragment key={name}>
            <Box>
              <Button fullWidth variant="contained" onClick={handleSectionClicked(name)}>{name}</Button>
            </Box>
            {idx < entry.tournament.sections.length - 1 && <br />}
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideMoveEntryToSectionDialog())}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveEntryToSectionDialog;