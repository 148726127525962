const JSEncrypt = require('jsencrypt-node');

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAlpRYVmeJN34SnOAxyOq0
fv5BqD8+pIQnXnz9303RUUtElUnfs3QwBu0sAcdx+7k/PujXL3gQ9VpeHGg0tn0I
mKGEncmXcFvo/IgDPd69jkuKPtIwLgXg9/oEJ2dNGwMJ70fsvRHBHqbN6iT1GNWY
s2I9X1+cj/id5ccfFAnTouJVJP6YGCP88VpLGgRS8Sm83NnOHUN5sU4Ncd/tLlR/
Sgf+EJUAMOpw6PWnfc41IemRUH0VQPssilTeFsJnBtT734KDrQ6MBTJ2b8P3YUrN
w2/3TDVMHZdyGTyHX6RWThYnmuAuCMeQSbJ+sQmdTkADwAj5sgJCQNu2bn48oXgx
UN4Oz7Ea8O5VyFE7+KnSJj2W8cJYxzPC+5045/DQvQvvWyJIGsAwWUzGtCKK+z/o
5VZQUypMsvIyQqm5I47Ub3uNnGKle0LzytbKWqhWQvUG92UIAy190z4Sul/Bst5h
wmy0T8YOiYl89ydj7petX/UKZRA45e4Oj/h+psNbPJcqZ4YP9zKyy9Pi0boYjcrO
s3vQnwaK4EneqOhKHCguH40QO7jbapqJD090yseXR4jPPzVjALt2jmXpZAWVVIfa
gBStHo4gGusGGSCIsmtS7BlAhfFuNRAjrwg19ZoV1E7X9BGZRoKo53PoyrssmezA
GUXzip32hifE5KZ2ZN4JrjcCAwEAAQ==
-----END PUBLIC KEY-----`;

let encryptor = null;

export const encrypt = (text) => {
  if (encryptor === null) {
    encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
  }
  return encryptor.encrypt(text);
};