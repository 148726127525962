import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../api/client';

export const putSession = createAsyncThunk('session/putSession', async (putSessionInput, thunkApi) => {
  return await client(thunkApi).put('/v1/sessions', putSessionInput, { withCredentials: true })
})

export const deleteSession = createAsyncThunk('session/deleteSession', async (thunkApi) => {
  return await client(thunkApi).delete('/v1/sessions', { withCredentials: true })
})

const sessionSlice = createSlice({
  name: 'session',
  initialState: { isLoggedIn: false, status: 'idle', showLogInDialog: false },
  reducers: {
    clearSession(state) {
      state.isLoggedIn = false
      state.emailAddress = null
      state.firstName = null
      state.lastName = null
      state.userExists = false
      state.isEmailAllowed = null
    },
    showLogInDialog(state) {
      state.showLogInDialog = true
    },
    hideLogInDialog(state) {
      state.showLogInDialog = false
    },
    setInvalidSessionMessage(state, action) {
      state.invalidSessionMessage = action.payload
    },
    setUserExists(state, action) {
      state.userExists = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(putSession.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(putSession.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(putSession.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.isLoggedIn = true
        const { sessionId, emailAddress, firstName, lastName, userExists, isEmailAllowed } = action.payload
        Object.assign(state, { sessionId, emailAddress, firstName, lastName, userExists, isEmailAllowed })
      })
      .addCase(deleteSession.pending, (state) => {
        state.status = 'deleting'
      })
      .addCase(deleteSession.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.status = 'idle'
        state.sessionId = undefined
      })
  }
})

export const { clearSession, showLogInDialog, hideLogInDialog, setInvalidSessionMessage, setUserExists } = sessionSlice.actions

export default sessionSlice.reducer
