import { configureStore } from '@reduxjs/toolkit';
import academies from '../features/academies/academiesSlice'
import session from '../features/session/sessionSlice'
import tournaments from '../features/tournaments/tournamentsSlice'
import users from '../features/users/usersSlice'
import fun from '../features/fun/funSlice'

export const store = configureStore({
  reducer: {
    academies,
    session,
    tournaments,
    users,
    fun,
  },
});
