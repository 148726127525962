import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useDispatch, useSelector } from 'react-redux';
import { setInvalidSessionMessage } from './sessionSlice';

const InvalidSessionDialog = () => {
  const dispatch = useDispatch()

  const message = useSelector(state => state.session.invalidSessionMessage)
  const show = !!message

  const handleClose = () => {
    dispatch(setInvalidSessionMessage(null))
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InvalidSessionDialog
