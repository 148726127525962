import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField as MUITextField, Stack } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckboxWithLabel, Select, TextField } from 'formik-mui';
import { createTournament, deleteTournament, hideCreateTournamentDialog, hideEditTournamentDialog, updateTournament } from './tournamentsSlice';
import { fetchAcademyCommerce } from '../academies/academiesSlice';
import { Spinner } from '../../components/Spinner';

const ratingChoices = [{
  id: 'uschess',
  label: 'US Chess',
}, {
  id: 'nwsrs',
  label: 'NWSRS'
}];

const CreateTournamentDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const creatingTournament = useSelector(state => state.tournaments.showCreateTournamentDialog)
  const editingTournament = useSelector(state => state.tournaments.showEditTournamentDialog)
  const tournamentToEdit = useSelector(state => state.tournaments.tournamentToEdit);
  const userAcademies = useSelector(state => state.academies.userAcademies);
  const academyCommerce = useSelector(state => state.academies.academyCommerce);
  const tournamentToReplicate = useSelector(state => state.tournaments.tournamentToReplicate);
  
  const [selectedAcademyId, setSelectedAcademyId] = useState('');  
  const [productsFilter, setProductsFilter] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [tournamentStartDate, setTournamentStartDate] = useState(new Date());
  const [tournamentEndDate, setTournamentEndDate] = useState(new Date());
  const [endDateHasChanged, setEndDateHasChanged] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const isProductSelected = (product, values) => values[`selectedProduct:${product.id || product.productId}`] && values[`selectedProduct:${product.id || product.productId}`].length;
  const isRatingSystemSelected = (ratingSystem, values) => values[`selectedRatingSystem:${ratingSystem.id}`] && values[`selectedRatingSystem:${ratingSystem.id}`].length;

  useEffect(() => {
    if (userAcademies.length) {
      setSelectedAcademyId(userAcademies[0].academyId);
    }
  }, [userAcademies])

  useEffect(() => {
    if (creatingTournament) {
      const academyId = tournamentToReplicate ? tournamentToReplicate.academyId : userAcademies[0].academyId;
      setSelectedAcademyId(academyId);
      setTournamentStartDate(tournamentToReplicate ? tournamentToReplicate.startDate : new Date());
      setTournamentEndDate(tournamentToReplicate ? tournamentToReplicate.endDate : new Date());
      setProductsFilter('');
      setFilteredProducts([]);
      setEndDateHasChanged(false);
      const initialValues = {
        name: tournamentToReplicate ? tournamentToReplicate.name : '',
        academyId,
      };
      ((tournamentToReplicate || ({ ratingsSystems : []})).ratingsSystems || []).forEach(ratingSystemId => {
        initialValues[`selectedRatingSystem:${ratingSystemId}`] = [ratingSystemId];
      });
      setInitialValues(initialValues);
    }
  }, [selectedAcademyId, creatingTournament, dispatch, userAcademies, tournamentToReplicate])

  useEffect(() => {
    if (editingTournament) {
      setSelectedAcademyId(tournamentToEdit.academyId);
      setTournamentStartDate(moment(tournamentToEdit.startDate));
      setTournamentEndDate(moment(tournamentToEdit.endDate));
      setProductsFilter('');
      setFilteredProducts([]);
      setEndDateHasChanged(false);
      
      const initialValues = {
        name: tournamentToEdit.name,
        publishRoster: tournamentToEdit.publishRoster,
        defaultRatingType: tournamentToEdit.defaultRatingType || '',
      };
      [
        ...(tournamentToEdit.commerce.externalProducts || []),
        ...(tournamentToEdit.commerce.products || [])
      ].forEach(productId => {
        initialValues[`selectedProduct:${productId}`] = [productId];
      });
      (tournamentToEdit.ratingsSystems || []).forEach(ratingSystemId => {
        initialValues[`selectedRatingSystem:${ratingSystemId}`] = [ratingSystemId];
      });
      setInitialValues(initialValues);

      if (!academyCommerce[tournamentToEdit.academyId]) {
        dispatch(fetchAcademyCommerce(tournamentToEdit.academyId));
      }
    }
  }, [selectedAcademyId, editingTournament, tournamentToEdit, dispatch, academyCommerce])

  useEffect(() => {
    if (creatingTournament && selectedAcademyId && academyCommerce && !academyCommerce[selectedAcademyId]) {
      dispatch(fetchAcademyCommerce(selectedAcademyId));
    }
  }, [creatingTournament, selectedAcademyId, dispatch, academyCommerce])

  useEffect(() => {
    if (editingTournament && initialValues && academyCommerce && academyCommerce[selectedAcademyId]) {
      setFilteredProducts([
        ...(academyCommerce[selectedAcademyId].externalProducts || []),
        ...(academyCommerce[selectedAcademyId].products || [])
      ].filter(product => {
        return isProductSelected(product, initialValues);
      }));    
    } else {
      setFilteredProducts([]);
    }
  }, [academyCommerce, editingTournament, initialValues, selectedAcademyId]);

  useEffect(() => {
    if (!creatingTournament && !editingTournament) {
      setInitialValues(null);
    }
  }, [creatingTournament, editingTournament])

  const handleCancel = () => {
    setInitialValues(null);
    dispatch(creatingTournament ? hideCreateTournamentDialog() : hideEditTournamentDialog())
  }

  const productsFilterChanged = (values) => (event) => {
    setProductsFilter(event.target.value);
    if (event.target.value.length >= 3) {
      setFilteredProducts([
        ...(academyCommerce[selectedAcademyId].externalProducts || []),
        ...(academyCommerce[selectedAcademyId].products || [])
      ].filter(product =>
        product.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0 || isProductSelected(product, values)
      ));
    } else {
      setFilteredProducts([
        ...(academyCommerce[selectedAcademyId].externalProducts || []),
        ...(academyCommerce[selectedAcademyId].products || [])
      ].filter(product => {
        return isProductSelected(product, values);
      }));
    }
  }

  const startDateChanged = (newValue) => {
    setTournamentStartDate(newValue);
    if (!endDateHasChanged) {
      setTournamentEndDate(newValue);
    }
  };

  const endDateChanged = (newValue) => {
    setTournamentEndDate(newValue);
    setEndDateHasChanged(true);
  };

  const handleDeleteTournamentClicked = () => {
    if (window.confirm(`Are you SURE you want to delete tournament ${tournamentToEdit.name}?`)) {
      const { academyId, tournamentId } = tournamentToEdit;
      dispatch(deleteTournament({ academyId, tournamentId }))
        .then(() => dispatch(hideEditTournamentDialog()))
        .then(() => navigate('/tournaments/'));
    }
  };  

  let isExternalCommerce = false;
  if (tournamentToEdit && academyCommerce && academyCommerce[tournamentToEdit.academyId]) {
    isExternalCommerce = academyCommerce[tournamentToEdit.academyId].commerceType === 'COMMERCE_EXTERNAL';
  }

  let dialogTitle;
  if (creatingTournament && tournamentToReplicate) {
    dialogTitle = 'Replicate Tournament';
  } else if (creatingTournament) {
    dialogTitle = 'Create Tournament';
  } else {
    dialogTitle = 'Edit Tournament';
  }

  return (
    <Dialog open={creatingTournament || editingTournament}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        { initialValues && (
          <Formik
            initialValues={initialValues}
            validate={(values => {
              const errors = {}
              if (!values.name) {
                errors.name = 'Required'
              }
              return errors
            })}
            onSubmit = {(values, { setSubmitting }) => {
              const { name, publishRoster, defaultRatingType } = values;
              const academyId = values.academyId || (tournamentToEdit && tournamentToEdit.academyId);
              const startDate = moment(tournamentStartDate)
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .set('millisecond', 0)
                .toDate().toISOString();
              const endDate = moment(tournamentEndDate)
                .set('hour', 23)
                .set('minute', 59)
                .set('second', 59)
                .set('millisecond', 999)
                .toDate().toISOString();
              const ratingsSystems = ratingChoices
                .filter(ratingChoice => isRatingSystemSelected(ratingChoice, values))
                .map(ratingChoice => ratingChoice.id);                
              const externalProducts = (academyCommerce[academyId].externalProducts || [])
                .filter(product => isProductSelected(product, values))
                .map(product => product.id);
              const products = (academyCommerce[academyId].products || [])
                .filter(product => isProductSelected(product, values))
                .map(product => product.productId);
              const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              const tournament = {
                academyId,
                name,
                publishRoster,
                defaultRatingType,
                startDate,
                endDate,
                timezone,
                ratingsSystems,
                commerce: { externalProducts, products },
                sections: [],
              };
              if (tournamentToReplicate) {
                tournament.tournamentToReplicate = tournamentToReplicate.tournamentId;
              }
              if (creatingTournament) {
                dispatch(createTournament({ academyId, tournament })).finally(() => {
                  setSubmitting(false);
                });
              } else {
                tournament.sections = tournamentToEdit.sections;
                tournament.instructions = tournamentToEdit.instructions;
                tournament.commerce.entryProperties = tournamentToEdit.commerce.entryProperties;
                const { tournamentId } = tournamentToEdit;
                dispatch(updateTournament({ academyId, tournamentId, tournament })).finally(() => {
                  setSubmitting(false);                  
                });
              }
            }}
          >
            {({ submitForm, values }) => (
              <Form>
                { creatingTournament && (
                  <React.Fragment>
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true, variant: 'standard' }}
                      inputLabel={{ variant: 'standard' }}
                      fullWidth
                      id="academyId"
                      name="academyId"
                      label="Academy"
                      onChange={(event) => setSelectedAcademyId(event.target.value)}
                    >
                      {userAcademies.map(({ academyId, academyName }) => (
                        <MenuItem key={academyId} value={academyId}>{academyName}</MenuItem>
                      ))}
                    </Field>
                    <br />
                    <div className="mt-2" />                  
                  </React.Fragment>
                )}
                <Field
                  component={TextField}
                  name="name"
                  label="Name"
                  margin="normal"
                />
                <br />
                <div className="mt-2" />
                <DatePicker
                  label="Start Date"
                  value={tournamentStartDate}
                  onChange={startDateChanged}
                  renderInput={(params) => <MUITextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={tournamentEndDate}
                  onChange={endDateChanged}
                  renderInput={(params) => <MUITextField {...params} />}
                />                
                <br />
                <div className="mt-2" />
                <Divider textAlign="left" className="mt-1">Ratings Systems (check all that apply)</Divider>
                { ratingChoices.map(({ id, label }) => (
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    key={id}
                    name={`selectedRatingSystem:${id}`}
                    value={id}
                    Label={{ label }}
                  />
                ))}
                { editingTournament && (
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name={'publishRoster'}
                    Label={{ label: 'Publish roster (available publicly to anyone with link)' }}
                  />
                )}
                { editingTournament && (
                  <React.Fragment>
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true, variant: 'standard' }}
                      inputLabel={{ variant: 'standard' }}
                      fullWidth
                      id="defaultRatingType"
                      name="defaultRatingType"
                      // value={defaultRatingType}
                      label="Default rating type"
                    >
                      {[
                        // <MenuItem key='default' value="">N/A</MenuItem>,
                        ...tournamentToEdit.availableRatingTypes.map(({ path, label }) => (
                          <MenuItem key={path} value={path}>{label}</MenuItem>
                        )),
                      ]}
                    </Field>
                    <br />
                    <div className="mt-2" />                  
                  </React.Fragment>
                )}
                <div className="mt-2" />
                { isExternalCommerce && <Divider textAlign="left" className="mt-1">Commerce Settings (External)</Divider> }
                { !isExternalCommerce && <Divider textAlign="left" className="mt-1">Commerce Settings</Divider> }
                <Spinner thunks={{ thunk: fetchAcademyCommerce, index: selectedAcademyId }} componentFn={() => (
                  <React.Fragment>                    
                    <div className="mt-3" />
                    <MUITextField
                      id='product-variant-filter'
                      placeholder='Variant name(s)'
                      label={isExternalCommerce ? 'External product variants' : 'Entry products'}
                      value={productsFilter}
                      onChange={productsFilterChanged(values)} />
                    < br />
                    <Stack direction="column">
                      { filteredProducts.map(product => (
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          key={product.id || product.productId}
                          name={`selectedProduct:${product.id || product.productId}`}
                          value={product.id || product.productId}
                          Label={{ label: product.name }}
                        />
                      ))}
                    </Stack>
                  </React.Fragment>
                )} />
                { creatingTournament && <Spinner thunks={createTournament} errorMessage={'Failed to create tournament'} />}
                { !creatingTournament && <Spinner thunks={updateTournament} errorMessage={'Failed to update tournament'} />}
                <DialogActions>
                  { !creatingTournament && <Button onClick={handleDeleteTournamentClicked} variant="outlined" color="error">Delete Tournament</Button>}
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button onClick={submitForm}>{creatingTournament ? 'Create' : 'Update'}</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>      
    </Dialog>
  )
}

export default CreateTournamentDialog