import { Alert } from "@mui/material";
import { useStore } from 'react-redux';

const ErrorAlert = ({ show = true, thunks, message }) => {
  const store = useStore();
  let showError;
  if (thunks) {
    const thunksArray = Array.isArray(thunks) ? thunks : [thunks];
    thunksArray.some((thunkArrayElement) => {
      let thunk, index;
      if (thunkArrayElement.thunk !== undefined) {
        thunk = thunkArrayElement.thunk;
        index = thunkArrayElement.index;
      } else {
        thunk = thunkArrayElement;
      }
      const { typePrefix } = thunk;
      const [reducerName, fieldName] = typePrefix.split('/');
      const statusValue = store.getState()[reducerName][`${fieldName}Status`];
      let status;
      if (index) {
        status = statusValue[index];
      } else {
        status = statusValue;
      }
      if (status === 'failed') {
        showError = true;
      }
      return showError;
    });
  } else {
    showError = !!show;
  }
  return showError && (
    <Alert severity="error">{message}. Please reach out to technical support.</Alert>
  );
}

export default ErrorAlert;