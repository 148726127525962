import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Input, InputAdornment, InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { toLower, toUpper, upperFirst, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import copy from 'copy-to-clipboard';
import stateAbbreviations from 'states-abbreviations';
import moment from 'moment';
import { hideViewTournamentEntryDialog } from "./tournamentsSlice";
import { entryIdNumber, entryName } from "./tournamentUtils";

const ViewTournamentEntryDialog = () => {
  const dispatch = useDispatch();
  const showViewTournamentEntryDialog = useSelector(state => state.tournaments.showViewTournamentEntryDialog);
  const tournamentEntry = useSelector(state => state.tournaments.tournamentEntryToView);
  let [dialogTitle, setDialogTitle] = useState('...');
  let [rawData, setRawData] = useState('...');
  let [mailingAddress, setMailingAddress] = useState('...');
  let [copyMailingAddressText, setCopyMailingAddressText] = useState('Copy');
  let [copyMailingAddressColor, setCopyMailingAddressColor] = useState('primary');
  let [USChessData, setUSChessData] = useState([]);
  let [copyScriptButtonColor, setCopyScriptButtonColor] = useState('primary');
  let [copyScriptButtonLabel, setCopyScriptButtonLabel] = useState('Copy Script');
  
  useEffect(() => {
    if (tournamentEntry && tournamentEntry.tournament && tournamentEntry.tournament.commerce) {
      const { entry, tournament } = tournamentEntry;
      const name = entryName({ tournament, entry });
      const usChessId = entryIdNumber({ tournament, entry });
      const usChessUrl = `https://uschess.org/assets/msa_joomla/MbrDtlMain.php?${usChessId}`;
      setDialogTitle(<div>{name} (<a href={usChessUrl} target="_blank" rel="noreferrer">{usChessId}</a>)</div>);
    }
    if (tournamentEntry && tournamentEntry.entry) {
      const { entry, tournament } = tournamentEntry;
      const { orderContact, customization = { properties: {}}, properties } = tournamentEntry.entry;
      const playerName = entryName({ tournament, entry })
      const usChessId = entryIdNumber({ tournament, entry });
      const property = (key) => (customization.properties || {})[key] || properties[key];
      if (orderContact.address1) {
        const { address1, address2, city, state, postalCode, countryCode } = orderContact;
        setMailingAddress([
          playerName,
          address1,
          address2,
          `${upperFirst(toLower(city))}, ${toUpper(state)} ${postalCode}`,          
        ].filter(e => !!e).join('\n'));

        const [firstName, ...lastName] = playerName.split(' ');
        setUSChessData([
          usChessId !== '...' && { label: 'Member ID', value: usChessId },
          { label: 'First Name', value: firstName },
          { label: 'Last Name', value: lastName.join(' ') },
          { label: 'Email', value: property('Email') || property('Adult Email') },
          {
            label: 'Birth Date',
            value:
              // TODO: add a commerce property for player birthdate (w/ function similar to entryName(), entryIdNumber(), etc...)
              property('Player\'s Birth Date') ||
              property('Player Birthday') ||
              property('Player\'s Birthdate') ||
              property('Player Birth Date')
          },
          { label: 'Gender', value: property('Player Gender') || property('Player\'s Gender'), canCopy: false },
          { label: 'Street Address', value: address1 },
          address2 && { label: 'Address Line 2', value: address2 },
          { label: 'City', value: upperFirst(toLower(city)) },
          { label: 'State', value: toUpper(state), canCopy: false },
          { label: 'Zip Code', value: postalCode },
          { label: 'Country', value: countryCode, canCopy: false },
        ]
          .filter(e => !!e && !!e.value)
          .map(({ label, value, canCopy }) => ({
            label,
            value: value.trim(),
            canCopy: canCopy !== false,
            copyButtonColor: 'primary',
            copyButtonLabel: 'Copy',
          }))
        );
      } else if (property('Mailing Address')) {
        setMailingAddress(property('Mailing Address'));
      }

      setRawData(JSON.stringify(tournamentEntry.entry, null, 2));
    }
  }, [tournamentEntry]);

  useEffect(() => {
    if (showViewTournamentEntryDialog) {
      setCopyMailingAddressText('Copy');
      setCopyMailingAddressColor('primary');
      setCopyScriptButtonLabel('Copy Script');
      setCopyScriptButtonColor('primary');
    }

  }, [showViewTournamentEntryDialog]);

  const copyMailingAddress = () => {
    copy(mailingAddress);
    setCopyMailingAddressText('Copy ✓');
    setCopyMailingAddressColor('success');
  };

  const copyUSChessData = (idx) => () => {
    copy(USChessData[idx].value);
    setUSChessData(USChessData.map((data, dataIdx) => idx === dataIdx
      ? { ...data, copyButtonLabel: 'Copy ✓', copyButtonColor: 'success' }
      : data
    ));
  };

  const copyUSChessScript = async () => {
    const clipboardText = await navigator.clipboard.readText();
    
    const data = keyBy(USChessData, 'label');
    let textToCopy = '';
    if (clipboardText && /^\d{5}-\d{5}-\d{5}-\d{5}$/.test(clipboardText)) {
      textToCopy += `document.getElementById('vouchercode').value='${clipboardText}';\n`;
    } else {
      textToCopy += `document.getElementById('vouchercode').value='';\n`;
    }
    if (data['Member ID'] && /^\d{8}$/.test(data['Member ID'].value)) {
      textToCopy += `document.getElementById('external_identifier').value='${data['Member ID'].value}';\n`;
    } else {
      textToCopy += `document.getElementById('external_identifier').value='';\n`;
    }
    textToCopy += `document.getElementById('first_name').value='${data['First Name'].value}';\n`;
    textToCopy += `document.getElementById('last_name').value='${data['Last Name'].value}';\n`;
    textToCopy += `document.getElementById('email-Primary').value='${data['Email'].value}';\n`;
    const birthDateStr = (data['Birth Date'] || { value: '' }).value;    
    if (birthDateStr) {
      textToCopy += `document.getElementsByClassName('crm-form-date-wrapper')[0].children[0].value='${moment(birthDateStr, 'MM-DD-YYYY').format('YYYY-MM-DD')}';\n`;
      textToCopy += `document.getElementsByClassName('crm-form-date-wrapper')[0].children[1].value='${birthDateStr}';\n`;
    } else {
      textToCopy += `document.getElementsByClassName('crm-form-date-wrapper')[0].children[0].value='';\n`;
      textToCopy += `document.getElementsByClassName('crm-form-date-wrapper')[0].children[1].value='';\n`;
    }
    if (data['Gender'].value.toLowerCase() === 'female') {
      textToCopy += 'document.getElementById(\'editrow-gender_id\').children[1].children[0].click();\n';
    } else if (data['Gender'].value.toLowerCase() === 'male') {
      textToCopy += 'document.getElementById(\'editrow-gender_id\').children[1].children[2].click();\n';
    } else if (data['Gender'].value.toLowerCase() === 'nonbinary') {
      textToCopy += 'document.getElementById(\'editrow-gender_id\').children[1].children[4].click();\n';
    }
    textToCopy += `document.getElementById('street_address-1').value='${data['Street Address'].value}';\n`;
    if (data['Address Line 2'] && data['Member ID'].value) {
      textToCopy += `document.getElementById('supplemental_address_1-1').value='${data['Address Line 2'].value}';\n`;
    } else {
      textToCopy += `document.getElementById('supplemental_address_1-1').value='';\n`;
    }
    textToCopy += `document.getElementById('city-1').value='${data['City'].value}';\n`;
    textToCopy += `document.getElementById('postal_code-1').value='${data['Zip Code'].value}';\n`;
    const stateName = stateAbbreviations[data['State'].value] || data['State'].value;
    textToCopy += `document.getElementById('select2-chosen-1').innerHTML='${stateName}';\n`;
    textToCopy += `document.getElementById('editrow-state_province-1').children[1].children[1].value=document.evaluate('//select/option[text() = \\'${stateName}\\']', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue.value;\n`;
    textToCopy += 'document.getElementById(\'crm-submit-buttons\').children[0].click();';
    
    copy(textToCopy);
    setCopyScriptButtonColor('success');
    setCopyScriptButtonLabel('Copy Script ✓');
  }
  
  return (
    <Dialog open={!!showViewTournamentEntryDialog}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Divider textAlign="left">Mailing address</Divider>
        <Box
          sx={{
            width: 800,
            maxWidth: '100%',
          }}
        >
          <TextField
            fullWidth 
            multiline
            value={mailingAddress}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button onClick={copyMailingAddress} variant="outlined" color={copyMailingAddressColor}>{copyMailingAddressText}</Button>
          <br />
          <br />
        </Box>
        <Divider textAlign="left">US Chess sign-up</Divider>
        <br />
        <div>
          <ol>
            <li>1. Copy a voucher code into your clipboard.</li>
            <li>2. Click <b>Copy Script</b> below. Grant permission to read from clipboard if prompted.</li>
            <li>3. Go to the <a className="underline" target="_blank" href="https://new.uschess.org/civicrm/contribute/transact?cid=0&reset=1&id=8" rel="noreferrer">US Chess voucher redemption page</a> in Chrome.</li>
            <li>4. Bring up Developer Tools (<b>Ctrl+Shift+i</b>).</li>
            <li>5. Paste the clipboard (<b>Ctrl+v</b>) into the Console tab and hit <b>Enter</b>.</li>
            <li>6. You should be taken to the verification screen. Click to confirm the information as normal.</li>
          </ol>
        </div>
        <p></p>
        <br />
        <Button onClick={copyUSChessScript} variant="outlined" color={copyScriptButtonColor}>{copyScriptButtonLabel}</Button>
        <br />
        <br />
        <p>If the above does not work or is too complicated, you can copy each field below and paste it into the voucher redemption form:</p>
        <br />
        {USChessData.map(({ label, value, canCopy, copyButtonColor, copyButtonLabel }, idx) => (
          <React.Fragment key={label}>            
            <InputLabel htmlFor={`view-tournament-entry-dialog-uschess-${idx}`}>{label}</InputLabel>
            <Input
              id={`view-tournament-entry-dialog-uschess-${idx}`}
              type='text'
              value={value}
              readOnly
              endAdornment={canCopy && (
                <InputAdornment position="end">
                  <Button onClick={copyUSChessData(idx)} variant="outlined" color={copyButtonColor}>{copyButtonLabel}</Button>
                </InputAdornment>
              )}
            />
          </React.Fragment>
        ))}
        <br />
        <br />
        <Divider textAlign="left">Raw data</Divider>
        <Box
          sx={{
            width: 800,
            maxWidth: '100%',
          }}
        >
          <TextField
            fullWidth 
            multiline
            value={rawData}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideViewTournamentEntryDialog())}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewTournamentEntryDialog;