import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import uniq from 'lodash.uniq';
import moment from 'moment';
import axios from 'axios';
import { hideExportTournamentDialog } from "./tournamentsSlice";

import ss11TemplateResource from './templates/ss11.sjson.ejs';

// Team codes upper-cased!
//
// "Teams": [
//   {
//     "Pair number": 1,
//     "Full name": "TEST",
//     "Team code": "TEST",
//     "Rating": 0,
//     "Current result": " ",
//     "Results": [
//     ]
//   },
//   {
//     "Pair number": 2,
//     "Full name": "TEST NUMBER TWO",
//     "Team code": "TEST2",
//     "Rating": 0,
//     "Current result": " ",
//     "Results": [
//     ]
//   }
// ],

const ExportTournamentDialog = () => {
  const dispatch = useDispatch();
  let ss11Template = null;

  const showExportTournamentDialog = useSelector(state => state.tournaments.showExportTournamentDialog);
  const tournamentToExport = useSelector(state => state.tournaments.tournamentToExport);

  const downloadAsSJSON = async () => {    
    if (ss11Template === null) {
      const ss11TemplateResponse = await axios.get(ss11TemplateResource);
      ss11Template = ss11TemplateResponse.data;
    }

    const {
      tournament, tournamentSections, entryRatings,
      entryNames,
      entryRatingExpirationDates, entryRatingIdNumbers, entryRatingTerritories,
      entryBoardOrders,
      entryTeams,
    } = tournamentToExport;

    const sectionTeams = {};
    tournamentSections.forEach(({ name, groups }) => {
      const currentSectionTeams = [];
      groups.forEach(({ entries }) => {
        entries.forEach(({ entryId }) => {
          const team = entryTeams[entryId];
          if (team) {
            currentSectionTeams.push(team);
          }
        });
      });
      sectionTeams[name] = uniq(currentSectionTeams).sort()
    });

    // eslint-disable-next-line no-undef
    const template = ejs.compile(ss11Template, {});
    const startDateFormatted = moment(tournament.startDate).format('M/D/YYYY');
    const endDateFormatted = moment(tournament.endDate).format('M/D/YYYY');
    const rendered = template({
      tournament, tournamentSections,
      startDateFormatted, endDateFormatted,
      entryNames,
      entryRatings, entryRatingExpirationDates, entryRatingIdNumbers, entryRatingTerritories,
      entryBoardOrders,
      entryTeams, sectionTeams,
    });

    const blob = new Blob([rendered], {type: "text/plain;charset=utf-8"});
    saveAs(blob, `${tournament.name.toLowerCase()}_${moment(tournament.startDate).format('MM-DD-YYYY')}.sjson`);
    dispatch(hideExportTournamentDialog());
  }

  return (
    <Dialog open={!!showExportTournamentDialog}>
      <DialogTitle>Export Tournament</DialogTitle>
      <DialogContent>
        <Button variant="contained" onClick={() => downloadAsSJSON()}>Download as .sjson</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideExportTournamentDialog())}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportTournamentDialog;