import axios from 'axios';

const apiRoot = window.location.hostname === 'localhost'
  ? 'http://localhost:3001'
  : 'https://api.yourchessacademy.com'

const request = ({ method, path, options, thunkApi, ...others }) => {
  return axios({
    method,
    url: `${apiRoot}${path}`,
    withCredentials: options && options.withCredentials,
    headers: Object.assign({}, (options && options.headers) || {}),
    ...others,
  })
    .then(response => response.data)
    .catch(error => {
      const { status, data } = error.response;
      return thunkApi.rejectWithValue({ status, data });
    });
}

const client = (thunkApi) => ({
  get: (path, options) => request({ method: 'get', path, options, thunkApi }),
  put: (path, data, options) => request({ method: 'put', path, data, options, thunkApi }),
  post: (path, data, options) => request({ method: 'post', path, data, options, thunkApi }),
  delete: (path, options) => request({ method: 'delete', path, options, thunkApi })
})

export default client
