import { Box, Button, Checkbox, FormControlLabel, FormGroup, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { setLuckyChessVariant, startOrStopSpinner, toggleFirstMoveChanged } from "./funSlice";
import LuckyChessSpinner from "./LuckyChessSpinner";

const description = [
  { text: 'Lucky Chess is a variant that normally involves dice with a chess piece on each side.' },
  { text: 'To play, set up a board as normal, (or have a class or camp set up their boards), and perform one roll before each side\'s turn to move.' },
  {
    text: 'Tip: once you\'ve clicked the START / STOP SPINNER button once, you can hit the spacebar to press it again.',
    className: 'hidden md:block'
  },
];

const instructions = {
  standard: ['Make a move with the piece that is announced.', 'If you can\'t, don\'t do anything!'],
  hyper: ['Move the first piece LIKE the second piece.'],
  turbo: ['Move the first piece and then HURL ONE FIREBALL in the direction of the second piece.', 'Fireballs capture the first enemy piece or pawn they touch.', 'Kings wear fireball-resistant armor.']
}

const LuckyChessPage = () => {
  const dispatch = useDispatch();
  const luckyChessVariant = useSelector(state => state.fun.luckyChessVariant);  
  const luckyChessSideToMove = useSelector(state => state.fun.luckyChessSideToMove);
  const luckyChessFirstMove = useSelector(state => state.fun.luckyChessFirstMove);

  const handleLuckyChessVariantChange = (_, value) => {
    if (value) {
      dispatch(setLuckyChessVariant(value));
    }
  };

  const handleStartStopSpinnerClicked = () => {
    dispatch(startOrStopSpinner());
  }

  const onFirstMoveChanged = () => {
    dispatch(toggleFirstMoveChanged());
  };

  return (
    <div className="m-4">
      <Typography variant="h3">Lucky Chess</Typography>
      <div className="mt-4 mb-4">{(description).map(({ text, className }, idx) => (
        <React.Fragment key={idx}>
          <Typography variant="body" className={className}>{text}</Typography>
          { idx < description.length - 1 && <br />}
        </React.Fragment>
      ))}</div>
      <ToggleButtonGroup
        className="m-4"
        color="primary"
        value={luckyChessVariant}
        exclusive
        onChange={handleLuckyChessVariantChange}
        aria-label="Platform"
      >
        <ToggleButton value="standard">Lucky Chess</ToggleButton>
        <ToggleButton value="hyper">Hyper Lucky Chess</ToggleButton>
        {/* <ToggleButton value="turbo">Turbo Lucky Chess</ToggleButton> */}
      </ToggleButtonGroup>
      <div className="mt-4 mb-4">{(instructions[luckyChessVariant] || []).map((line, idx) => (
        <React.Fragment key={idx}>
          <div className="m-2 mb-3 text-4xl font-bold">{line}</div>
        </React.Fragment>
      ))}</div>
      <div className="mt-6 mb-2 text-center">
        <Button variant="contained" size="large" color="success" onClick={handleStartStopSpinnerClicked}>Start / Stop Spinner</Button>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={luckyChessFirstMove} onChange={onFirstMoveChanged}/>}
            label="First move"
            sx={{ justifyContent: 'center' }}
          />
        </FormGroup>
      </div>
      <div className="m-2 text-center">
        <Typography variant="h3" className=""><Box fontWeight='fontWeightBold' display='inline'>{luckyChessSideToMove}</Box> to move:</Typography>
      </div>      
      {luckyChessVariant === 'standard' && <React.Fragment>
        <LuckyChessSpinner label='Piece' spinnerIndex={1} firstMoveChoices={['pawn', 'knight']} />
      </React.Fragment>}
      {luckyChessVariant === 'hyper' && <React.Fragment>
        <LuckyChessSpinner label='Piece' spinnerIndex={1} firstMoveChoices={['pawn']} />
        <LuckyChessSpinner label='Moves like a' spinnerIndex={2} />
      </React.Fragment>}
      {luckyChessVariant === 'turbo' && <React.Fragment>
        <LuckyChessSpinner label='Piece' spinnerIndex={1} />
        <LuckyChessSpinner
          label='Moves normally and then hurls a fireball like a'
          choices={['knight', 'bishop', 'rook', 'queen', 'king']}
          spinnerIndex={2}
        />
      </React.Fragment>}
    </div>
  );
}

export default LuckyChessPage;