import { ArrowBack, CloudUpload, CopyAll, Download, Edit, Email, PersonAddAlt1, Reorder, Save, Settings, Upload, Visibility, Window } from "@mui/icons-material";
import { Alert, Box, Button, Divider, IconButton, Link as MUILink, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import orderby from 'lodash.orderby';
import some from 'lodash.some';
import get from 'lodash.get';
import every from 'lodash.every';
import { upperFirst, uniq } from 'lodash';
import copy from 'copy-to-clipboard';
import { fetchTournament, hideAddTournamentEntryDialog, hideEditTournamentDialog, hideEditTournamentEntrySettingsDialog, listTournamentEntries, listTournaments, scanTournament, showAddTournamentEntryDialog, showCustomizeTournamentEntryDialog, showEditTournamentDialog, showEditTournamentEntrySettingsDialog, showExportTournamentDialog, showImportTournamentResourceDialog, showManageTournamentSectionsDialog, showMoveEntryToSectionDialog, showViewAttributesDialog, showViewTournamentEntryDialog, updateTournament } from "./tournamentsSlice";
import { Spinner } from "../../components/Spinner";
import TournamentInstructionsEditor from "./TournamentInstructionsEditor";
import { dateText, entryCreationDate, entryEmail, entryIdNumber, entryName, entryNWSRSIdNumber, entryUSChessIdNumber } from "./tournamentUtils";

const USCHESS_ID_EXP = /\d{8}/;
const NWSRS_ID_EXP = /[A-Z][A-Z0-9]{7}/;

const entryLightTextClass = 'text-gray-400';
const entryDangerClass = 'font-bold text-red-600';
const groupNameClass = 'font-bold';
const groupNameDangerClass = 'font-bold text-red-600';

const ManageTournamentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { academyId, tournamentId } = useParams();
  const listTournamentsStatus = useSelector(state => state.tournaments.listTournamentsStatus);
  const createTournamentEntryStatus = useSelector(state => state.tournaments.createTournamentEntryStatus);
  const tournaments = useSelector(state => state.tournaments.tournaments);
  const tournamentEntries = useSelector(state => state.tournaments.tournamentEntries);
  const updateTournamentStatus = useSelector(state => state.tournaments.updateTournamentStatus);
  const showingEditTournamentEntrySettingsDialog = useSelector(state => state.tournaments.showEditTournamentEntrySettingsDialog);
  const showingEditTournamentDialog = useSelector(state => state.tournaments.showEditTournamentDialog)
  const viewAttributes = useSelector(state => state.tournaments.viewAttributes);

  const [entryOrdersScanSubmitted, setEntryOrdersScanSubmitted] = useState(false);
  const [fetchedTournament, setFetchedTournament] = useState(false);
  let [tournament, setTournament] = useState(null);
  let [instructions, setInstructions] = useState(null);

  const tournamentEntriesKey = `${academyId}::${tournamentId}`;
  const tournamentViewAttributes = viewAttributes[tournamentEntriesKey] || [];

  useEffect(() => {
    if (listTournamentsStatus === 'idle') {
      dispatch(listTournaments());
    }
  }, [listTournamentsStatus, dispatch]);

  useEffect(() => {
    const matchingTournament = (tournaments || []).find((t) => t.academyId === academyId && t.tournamentId === tournamentId);
    setTournament(matchingTournament);
    if (matchingTournament) {
      setInstructions(matchingTournament.instructions);
    }
  }, [academyId, tournamentId, tournaments, setTournament]);

  useEffect(() => {
    if (tournament && !tournament.commerce) {
      setFetchedTournament(true);
      dispatch(fetchTournament({ academyId, tournamentId }));
    }
  }, [academyId, dispatch, tournamentId, tournament]);

  useEffect(() => {
    if (tournamentEntries[tournamentEntriesKey] === undefined) {
      dispatch(listTournamentEntries({ academyId, tournamentId }));
    }
  }, [tournamentEntries, dispatch, tournamentEntriesKey, academyId, tournamentId]);

  useEffect(() => {
    if (updateTournamentStatus === 'succeeded') {
      if (showingEditTournamentDialog) {
        dispatch(hideEditTournamentDialog());
      } else if (showingEditTournamentEntrySettingsDialog) {
        dispatch(hideEditTournamentEntrySettingsDialog());
      }
    }
  }, [updateTournamentStatus, showingEditTournamentDialog, showingEditTournamentEntrySettingsDialog, dispatch]);

  useEffect(() => {
    if (createTournamentEntryStatus === 'succeeded') {
      dispatch(hideAddTournamentEntryDialog());
    }
  });

  const entryRating = (entry) => {
    return (entry.customization && entry.customization.rating) || entryRatings[entry.entryId];
  }

  const ratingNumber = (ratingStr) => {
    return ratingStr && ratingStr[0] !== 'u'
      ? parseInt(ratingStr.split(' ')[0])
      : 0;
  }

  const entryRatingNumber = (entry) => {
    return ratingNumber(entryRating(entry));
  }

  const entryGroupOrderNumber = (entry) => {
    if (entry.customization && entry.customization.groupOrder) {
      return parseInt(entry.customization.groupOrder);
    }
    return Number.MAX_SAFE_INTEGER;
  };

  const entries = tournamentEntries[tournamentEntriesKey];
  const hasEntries = tournamentEntries[tournamentEntriesKey] && entries.length > 0;
  const hasEntryPropertiesList = tournament && !!tournament.entryProperties;
  const isExternalCommerce = tournament && tournament.commerceType === 'COMMERCE_EXTERNAL';
  const lacksEntryCommerce = tournament && tournament.commerce && (!tournament.commerce.entryProperties || tournament.commerce.entryProperties.nameProperty === '');
  const tournamentSections = [];
  const entryNames = {};
  const entryRatings = {};
  const entryRatingExpirationDates = {};
  const entryRatingIdNumbers = {};
  const entryBoardOrders = {};
  const entryTeams = {};
  const entryRatingTerritories = {};  
  const instructionsChanged = tournament && tournament.instructions !== instructions;
  const numActiveEntries = entries
    ? entries.filter((entry) => !entry.customization || !entry.customization.deactivated).length
    : 0;
  let deactivatedPlayersSection = null;
  let uncategorizedSection = null;
  const groupingValuesForConditionFields = {};
  ((tournament && tournament.groupingOptions) || []).forEach(({ path, entryProperty, values }) => {
    if (values) {
      groupingValuesForConditionFields[path || `properties.${(tournament.commerce.entryProperties || {})[entryProperty]}`] = values;
    }
  });
  if (tournament && tournament.sections) {
    tournamentSections.push.apply(tournamentSections, orderby(tournament.sections, 'order').map((section) => ({
      ...section,
      groups: [],
      numEntries: 0,
    })));
  }
  
  const addToSection = (section, entry) => {
    let matchingGroup;
    if (section.groupingFields) {
      const groupingAttributeValue = get(
        entry.customization || { properties: {} },
        section.groupingFields,
        get(entry, section.groupingFields)
      )
      if (!section.groups.some(group => {
        if (group.attributeValue === groupingAttributeValue) {
          matchingGroup = group;
          return true;
        }
      })) {
        const groupingFieldsSegments = section.groupingFields.split('.');
        matchingGroup = {
          name: `${upperFirst(groupingFieldsSegments[groupingFieldsSegments.length-1])}: ${groupingAttributeValue || 'Not Yet Assigned'}`,
          attributeValue: groupingAttributeValue,
          entries: []
        };
        section.groups.push(matchingGroup);
      }      
    } else {
      if (section.groups.length === 0) {
        section.groups.push({ name: 'Default Group', isDefaultGroup: true, entries: [] });
      }
      matchingGroup = section.groups[0];
    }
    matchingGroup.entries.push(entry);
    section.numEntries += 1;
  };

  (entries || []).forEach((entry) => {
    const [firstName, ...lastNameParts] = (entryName({ tournament, entry }) || '???').split(' ');
    entryNames[entry.entryId] = { firstName, lastName: lastNameParts.join(' ') };
    entryRatingExpirationDates[entry.entryId] = (
      entry.ratings && entry.ratings.USChess && entry.ratings.USChess.expirationDate && moment(entry.ratings.USChess.expirationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
    ) || '';
    const idNumber = entryUSChessIdNumber({ tournament, entry });
    if (idNumber && USCHESS_ID_EXP.test(idNumber)) {
      entryRatingIdNumbers[entry.entryId] = idNumber;
    }    
    entryRatingTerritories[entry.entryId] = (entry.ratings && entry.ratings.USChess && entry.ratings.USChess.state) || '';
    if (entry.customization && entry.customization.team) {
      entryTeams[entry.entryId] = entry.customization && entry.customization.team;
    }
    if (entry.customization && entry.customization.groupOrder) {
      entryBoardOrders[entry.entryId] = parseInt(entry.customization.groupOrder);
    }

    if (entry.customization && entry.customization.deactivated === true) {        
      if (deactivatedPlayersSection === null) {
        tournamentSections.push(deactivatedPlayersSection = {
          name: 'Deactivated',
          groups: [{ name: 'Default Group', isDefaultGroup: true, entries: [] }],
          isDeactivatedSection: true,
          numEntries: 0,
        });
      }
      deactivatedPlayersSection.groups[0].entries.push(entry);
      deactivatedPlayersSection.numEntries += 1;
      let rating = get(entry.ratings, (tournament && tournament.defaultRatingType) || 'USChess.regular');
      if (rating) {
        entryRatings[entry.entryId] = rating;
      }
    } else if (!tournament || !tournament.sections || !some(tournament.sections, (section) => {
      let matchesSection;
      const [primaryRating, secondaryRating] = section.ratingTypes;
      let rating = get(entry.ratings, primaryRating);
      if (!rating || 'unr.' === rating) {
        rating = get(entry.ratings, secondaryRating);
      }
      if (entry.customization && entry.customization.section && tournament.sections.some(({ name }) => name === entry.customization.section )) {
        matchesSection = entry.customization.section === section.name;
      } else {
        matchesSection = every(section.conditions, ({ field, comparator, value }) => {
          let entryValue, conditionValue;
          if (field === 'rating') {
            entryValue = ratingNumber((entry.customization && entry.customization.rating) || rating);
          } else {
            entryValue = get(
              entry.customization || { properties: {} },
              field,
              get(entry, field)
            );
          }
          if (groupingValuesForConditionFields[field]) {
            entryValue = groupingValuesForConditionFields[field].indexOf(entryValue);
            conditionValue = groupingValuesForConditionFields[field].indexOf(value);
          } else {
            conditionValue = value;
          }
          switch (comparator) {
            case 'EQ':
              return entryValue === conditionValue;
            case 'GTE':
              return entryValue >= conditionValue;
            case 'LTE':
              return entryValue <= conditionValue;
            default:
              return false;
          }
        });
      }
      if (matchesSection) {
        addToSection(tournamentSections[section.order], entry);        
        entryRatings[entry.entryId] = ratingNumber((entry.customization && entry.customization.rating) || (rating && rating.toString()) || 'unr.').toString();
        return true;
      }
    })) {
      if (uncategorizedSection === null) {
        uncategorizedSection = {
          name: 'Uncategorized entries',
          groups: [],
          numEntries: 0,
          additionalDisplayFields: '',
          isUncategorizedSection: true
        };
        tournamentSections.push(uncategorizedSection);
      }
      addToSection(uncategorizedSection, entry);
      let rating = get(entry.ratings, (tournament && tournament.defaultRatingType) || 'USChess.regular');
      entryRatings[entry.entryId] = ratingNumber((entry.customization && entry.customization.rating) || (rating && rating.toString()) || 'unr.').toString();
    }
  });

  tournamentSections.forEach(tournamentSection => {
    tournamentSection.groups = orderby(tournamentSection.groups, ['name'], ['asc']);
    tournamentSection.groups.forEach(group => {
      let sortFields = [];
      let sortDirections = [];
      if (!group.isDefaultGroup) {
        sortFields.push(entryGroupOrderNumber);
        sortDirections.push('asc');
      }
      const { sortingFields : sectionSortFields } = tournamentSection;
      (sectionSortFields || 'rating:desc,name:asc')
        .split(',')
        .map(elem => elem.trim())
        .filter(elem => !!elem)
        .forEach(fieldNameAndDirection => {
          let [fieldName, direction = 'asc'] = fieldNameAndDirection.split(':');
          if (fieldName === 'rating') {
            sortFields.push(entryRatingNumber);
          } else if (fieldName === 'name') {
            sortFields.push((entry) => entryName({ tournament, entry }));
          } else {
            sortFields.push((entry) => get(
              entry.customization || { properties: {} },
              fieldName,
              get(entry, fieldName)
            ));
          }
          sortDirections.push(direction);
        });
      group.entries = orderby(group.entries, sortFields, sortDirections);
    });    
  });

  const onEntryClick = (entry) => {
    dispatch(showViewTournamentEntryDialog({ tournament, entry }));
  };

  const onCustomizeEntryClicked = ({ entry, section, group, rating }) => {
    dispatch(showCustomizeTournamentEntryDialog({ tournament, entry, section, group, rating }));
  };

  const onAssignEntryToSectionClicked = ({ entry }) => {
    dispatch(showMoveEntryToSectionDialog({ tournament, entry }));
  }

  const USChessIDSegment = (entry) => {
    let href = entry.ratings && entry.ratings.USChess ? entry.ratings.USChess.homepage : undefined;
    let labelText = entryUSChessIdNumber({ tournament, entry });
    let isIdNumberValid = USCHESS_ID_EXP.test(labelText);
    if (isIdNumberValid && entry.ratings && entry.ratings.USChess) {
      const { expirationDate: expirationDateStr } = entry.ratings.USChess;
      if (expirationDateStr) {
        if (expirationDateStr.indexOf('0000') === 0) {
          isIdNumberValid = false;
          labelText += ' (US Chess signup incomplete)';
        } else {
          const expirationDate = moment(expirationDateStr, 'YYYY-MM-DD');
          const tournamentDate = moment(tournament.startDate).toDate();
          if (expirationDate < tournamentDate) {
            isIdNumberValid = false;
            if (expirationDate < Date.now()) {
              labelText += ` (expired ${expirationDateStr})`;
            } else {
              labelText += ` (expires ${expirationDateStr})`;
            }
          }
        }
      }
    }
    if (!labelText) {
      href = undefined;
      labelText = 'N/A';
      isIdNumberValid = false;
    }
    const key = `ratings-uschess`;
    const className = isIdNumberValid ? entryLightTextClass : entryDangerClass;
    if (href) {
      return (<a className={className} key={key} href={href} target="_blank" rel="noreferrer">{labelText}</a>);
    } else {
      return (<span className={className} key={key}>{labelText}</span>);
    }
  }

  const NWSRSIDSegment = (entry) => {
    let href = entry.ratings && entry.ratings.NWSRS ? entry.ratings.NWSRS.homepage : undefined;
    let labelText = entryNWSRSIdNumber({ tournament, entry });
    let isIdNumberValid = NWSRS_ID_EXP.test(labelText) && href !== undefined;
    if (isIdNumberValid) {
      const grade = get(entry.ratings, 'NWSRS.grade', '??');
      let gradeText;
      switch(grade) {
        case '??':
        case 'Adult':
          gradeText = grade;
          break;
        case '1':
          gradeText = `${grade}st grade`;
          break;
        case '2':
          gradeText = `${grade}nd grade`;
          break;
        case '3':
          gradeText = `${grade}rd grade`;
          break;
        default:
          gradeText = `${grade}th grade`;
          break;
      }
      labelText = `${labelText} (${gradeText})`;
    } else {
      isIdNumberValid = labelText === 'n/a';
    }
    if (!labelText) {
      href = undefined;
      labelText = 'N/A';
      isIdNumberValid = false;
    }
    const key = `ratings-nwsrs`;
    const className = isIdNumberValid ? entryLightTextClass : 'font-bold text-red-600';
    if (href) {
      return (<a className={className} key={key} href={href} target="_blank" rel="noreferrer">{labelText}</a>);
    } else {
      return (<span className={className} key={key}>{labelText}</span>);
    }
  }

  const entryListItem = (entry, section, group) => {
    const name = `${entryNames[entry.entryId].firstName} ${entryNames[entry.entryId].lastName}`;
    const rating = entryRating(entry);
    let className = 'group hover:bg-orange-100';
    if (entry.customization && entry.customization.deactivated === true) {
      className += ` ${entryLightTextClass} italic`;
    }
    const attributeElements = [];
    tournamentViewAttributes.forEach((attributePath, idx) => {
      if (attributeElements.length) {
        attributeElements.push(<span key={`sep-attributes-${idx}`}> | </span>);
      }
      switch(attributePath) {
        case 'uschess-id':
          attributeElements.push(USChessIDSegment(entry));
          break;
        case 'nwsrs-id':
          attributeElements.push(NWSRSIDSegment(entry));
          break;
        default:
          const attributeValue = get(
            entry.customization || { properties: {} },
            attributePath,
            get(entry, attributePath)
          );
          const segments = attributePath.split('.');
          const attributeName = upperFirst(segments[segments.length-1]);
          attributeElements.push(<Tooltip title={attributeName} key={attributePath}>
            <span className={entryLightTextClass}>{attributeValue}</span>
          </Tooltip>)
          break;
      }      
    });
    return (
      <li key={entry.entryId} className={className}>
        <Link href="#" underline="hover" onClick={() => onEntryClick(entry)}>
          {name}: {(rating !== '0' && rating) || 'unr.'}
        </Link>          
        {attributeElements.length
          ? <span> {attributeElements}</span>
          : null
        }
        <Tooltip title="Customize Entry">
          <IconButton
            size="small"
            aria-label="Customize entry"
            onClick={() => onCustomizeEntryClicked({ entry, section, group, rating: rating || '' })}
            color="inherit"
            classes={{root: "lg:invisible group-hover:visible"}}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        {!section.isDeactivatedSection && <Tooltip title="Assign Entry to Section">
          <IconButton
            size="small"
            aria-label="Assign to section"
            onClick={() => onAssignEntryToSectionClicked({ entry })}
            color="inherit"
            classes={{root: "lg:invisible group-hover:visible"}}
          >
            <Window />
          </IconButton>
        </Tooltip>}
      </li>
    );
  }

  const onSaveInstructionsClicked = () => {
    const { academyId, tournamentId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, commerce, ratingsSystems, sections } = tournament;
    const updatedTournament = { academyId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, commerce, ratingsSystems, sections, instructions };
    dispatch(updateTournament({ academyId, tournamentId, tournament: updatedTournament }));
  };

  const onAddEntryClicked = () => {
    dispatch(showAddTournamentEntryDialog(tournament));
  };

  const onViewEntryPropertiesClicked = () => {
    dispatch(showViewAttributesDialog(tournament));
  }

  const onExportTournamentClicked = () => {
    dispatch(showExportTournamentDialog({
      tournament,
      tournamentSections: tournamentSections.filter(ts => !ts.isDeactivatedSection),
      entryNames,
      entryRatings, entryRatingExpirationDates, entryRatingIdNumbers, entryRatingTerritories,
      entryBoardOrders,
      entryTeams,
    }));
  };

  const onImportTournamentResourceClicked = () => {
    dispatch(showImportTournamentResourceDialog(tournament));
  };
  
  const onManageSectionsClicked = () => {
    dispatch(showManageTournamentSectionsDialog(tournament));
  };
  
  const onConfigureEntrySettingsClicked = () => {
    dispatch(showEditTournamentEntrySettingsDialog(tournament));
  };

  const onCopySectionEmailAddresses = (section) => () => {
    const sections = section ? [section] : tournamentSections.filter(section => !section.isDeactivatedSection);
    const entries = [];
    sections.forEach((section) => {
      section.groups.forEach((group) => {
        entries.push.apply(entries, group.entries);
      });
    });    
    copy(uniq(orderby(entries, (entry) => entryCreationDate({ entry }))
      .map((entry) => entryEmail({ tournament, entry }))
      .filter(v => !!v)
    ).join(','));
  }

  const onCopySectionCSVClicked = (section) => async (event) => {
    const sections = section ? [section] : tournamentSections.filter(section => !section.isDeactivatedSection && section.numEntries);
    let entryIdx = 1;

    const data = await navigator.clipboard.read();

    let clipboardHtml = '';
    clipboardHtml += '<table xmlns="http://www.w3.org/1999/xhtml" cellspacing="0" cellpadding="0" dir="ltr" border="1" style="table-layout: fixed; font-size: 10pt; font-family: Arial; width: 0px;">';    
    const clipboardText = sections.map((section) => {
      const entries = [];
      section.groups.forEach((group) => {
        entries.push.apply(entries, group.entries);
      });
      return entries.map((entry, idx) => {
        const entryIndex = entryIdx++;
        const displayFields = section.additionalDisplayFields[0] === '^'
          ? section.additionalDisplayFields.substring(1).split(',')
          : ['number', 'name', 'rating', ...section.additionalDisplayFields.split(',')];
        if (idx === 0) {
          clipboardHtml += '<colgroup>';
          displayFields.forEach(() => {
            clipboardHtml += '<col width="100">';
          });
          clipboardHtml += '</colgroup>';
          clipboardHtml += '<tbody>';
        }
        clipboardHtml += '<tr style="height: 21px;">';
        let lastVal = null, colSpan = 1;
        const appendToClipboardHtml = (val) => {
          const style = 'color: rgb(0, 0, 0); font-family: Arial; font-size: 13.3333px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; border-top: 1px solid rgb(0, 0, 0); border-right: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(0, 0, 0); overflow: hidden; padding: 2px 3px; vertical-align: bottom;';
          const valText = (lastVal || '').toString().trim();
          if (colSpan === 1) {
            clipboardHtml += `<td data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;${valText}&quot;}" style="${style}">${valText}</td>`;
          } else {
            clipboardHtml += `<td rowspan="1" colspan="${colSpan}" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;${valText}&quot;}" style="${style}">${valText}</td>`;
          }
          lastVal = val;
          colSpan = 1;
        };
        const textLine = displayFields
          .map((elem) => elem.trim())
          .map((fieldPath, idx) => {
            let val;
            if (fieldPath === 'number') {
              val = entryIndex;
            } else if (fieldPath === 'name') {
              val = entryName({ tournament, entry });
            } else if (fieldPath === 'rating') {
              const rating = entryRating(entry);
              val = ((rating !== '0' && rating) || 'unr.').split(' ')[0];
            } else if (fieldPath === '') {
              val = null;
            } else {
              val = get(
                entry.customization || { properties: {} },
                fieldPath,
                get(entry, fieldPath)
              );
            }
            if (fieldPath === '') {
              colSpan += 1;
            } else if (lastVal === null) {
              lastVal = val;
            } else {
              appendToClipboardHtml(val);
            }
            return val;
          })
          .map(v => v || '').join('\t');
          appendToClipboardHtml();
          clipboardHtml += '</tr>';
        return textLine;
      }).join('\n')
    }).join('\n');
    clipboardHtml += '</tbody></table>';//</google-sheets-html-origin>';
    const clipboardItem = {
      'text/html': new Blob([clipboardHtml], { type: 'text/html' }),      
    };
    if (event.shiftKey !== true) {
      clipboardItem['text/plain'] = new Blob([clipboardText], { type: 'text/plain' });
    }
    await navigator.clipboard.write([new window.ClipboardItem(clipboardItem)]);
  };

  const onScanEntriesClicked = () => {
    const { academyId, tournamentId, startDate } = tournament;
    if (moment(startDate).toDate() <= Date.now()) {
      if (!window.confirm('The tournament start date is in the past. Scanning entries will fetch current ratings. Are you sure you want to continue?')) {
        return;
      }
    }
    const scanRequest = { entries: true };
    dispatch(scanTournament({ academyId, tournamentId, scanRequest })).then(() => {
      setEntryOrdersScanSubmitted(true);
    })
  }

  const generalPrereqs = [
    fetchedTournament ? fetchTournament : null,
    listTournaments,
  ].filter(e => !!e);

  return (
    <div className="m-4">
      <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate("/tournaments")}>
        Tournaments
      </Button>
      <Divider textAlign="left">General</Divider>
      <Spinner thunks={generalPrereqs} isLoadingFn={() => !tournament} errorMessage={'Failed to load tournament'} componentFn={() => (
        <React.Fragment>
          <Typography variant="h5">{tournament.name}</Typography>
          <Typography variant="h6">{dateText(tournament)}</Typography>
          <Button variant="outlined" onClick={() => dispatch(showEditTournamentDialog(tournament))}>Edit Tournament Details</Button>
          <br />
          <br />
          {!instructionsChanged && <Divider textAlign="left">Instructions</Divider>}
          {instructionsChanged && <Divider textAlign="left" className="font-bold">Instructions *</Divider>}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Toolbar disableGutters={true}>
              <Tooltip title="Update Instructions">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Update instructions"
                  onClick={onSaveInstructionsClicked}
                  color="inherit"
                >
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </Box>  
          <TournamentInstructionsEditor tournament={tournament} onChange={setInstructions}/>
          <br />
        </React.Fragment>
      )} />
      <br />
      <Divider textAlign="left">Entries{hasEntries && ` (${numActiveEntries})`}</Divider>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Toolbar disableGutters={true}>
          <Tooltip title="Copy Email Addresses">
            <IconButton
              size="large"
              edge="end"
              aria-label="Copy Email Addresses"
              onClick={onCopySectionEmailAddresses()}
              color="inherit"
            >
              <Email />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy Excel CSV">
            <IconButton
              size="large"
              edge="end"
              aria-label="Copy CSV"
              onClick={onCopySectionCSVClicked()}
              color="inherit"
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
          {hasEntries && <Tooltip title="Show/Hide Entry Attributes">
            <IconButton
              size="large"
              edge="end"
              aria-label="Show/hide entry attributes"
              onClick={onViewEntryPropertiesClicked}
              color="inherit"
            >
              <Visibility />
            </IconButton>
          </Tooltip>}
          {(hasEntries || hasEntryPropertiesList) && <Tooltip title="Add Entry">
            <IconButton
              size="large"
              edge="end"
              aria-label="Add entry"
              onClick={onAddEntryClicked}
              color="inherit"
            >
              <PersonAddAlt1 />
            </IconButton>
          </Tooltip>}          
          <Tooltip title="Download Tournament Resources">
            <IconButton
              size="large"
              edge="end"
              aria-label="Export tournament"
              onClick={onExportTournamentClicked}
              color="inherit"
            >
              <Download />
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload Tournament Resources">
            <IconButton
              size="large"
              edge="end"
              aria-label="Import tournament resource"
              onClick={onImportTournamentResourceClicked}
              color="inherit"
            >
              <Upload />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manage Sections">
            <IconButton
              size="large"
              edge="end"
              aria-label="Manage sections"
              onClick={onManageSectionsClicked}
              color="inherit"
            >
              <Window />
            </IconButton>
          </Tooltip>
          {isExternalCommerce && <Tooltip title="Manage Tournament Entry Settings">
            <IconButton
              size="large"
              edge="end"
              aria-label="Entry settings"
              onClick={onConfigureEntrySettingsClicked}
              color="inherit"
            >
              <Settings />
            </IconButton>
          </Tooltip>
          }
        </Toolbar>
      </Box>      
      <Spinner thunks={listTournamentEntries} errorMessage={'Failed to load tournament entries'} componentFn={() => (
        <React.Fragment>
          {hasEntries && lacksEntryCommerce && (
            <React.Fragment>
              <Alert severity="warning">More information is required to display tournament entries. Click <Link component="button" variant="body2" onClick={() => onConfigureEntrySettingsClicked()}>here</Link> to configure tournament entry settings.</Alert>
            </React.Fragment>
          )}
          {hasEntries && !lacksEntryCommerce && tournamentSections.map((section) => (
            <React.Fragment key={section.name}>
              <Typography variant="h6" className="group hover:bg-orange-100">
                <span>{section.name} ({section.numEntries})</span>
                <Tooltip title="Copy Section Email Addresses">
                  <IconButton
                    size="small"
                    aria-label="Copy Email Addresses"
                    onClick={onCopySectionEmailAddresses(section)}
                    color="inherit"
                    classes={{root: "lg:invisible group-hover:visible"}}
                  >
                    <Email />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy Section Excel CSV">
                  <IconButton
                    size="small"
                    aria-label="Copy CSV"
                    onClick={onCopySectionCSVClicked(section)}
                    color="inherit"
                    classes={{root: "lg:invisible group-hover:visible"}}
                  >
                    <CopyAll />
                  </IconButton>
                </Tooltip>
              </Typography>          
              <ol>
                {section.groups.map((group) => [
                  !group.isDefaultGroup && (<li
                    key={group.name}
                    className={group.attributeValue ? groupNameClass : groupNameDangerClass}>
                      {group.name}
                  </li>),
                  ...group.entries.map((entry) => entryListItem(entry, section, group))
                ].filter(e => !!e))}
              </ol>
            </React.Fragment>
          ))}
          <br />
          <Divider />
          <br />
          { tournament && (
            <div>
              <Typography variant="body">
                <span>{tournament.ordersLastScannedTime
                  ? `Entry orders were last scanned ${moment(tournament.ordersLastScannedTime).fromNow()}.`
                  : 'Entry orders have not been scanned.'
                }</span>
                {entryOrdersScanSubmitted
                  ? <span className="font-bold ml-1">{'Reload page in a few minutes to see if there are new entries.'}</span>
                  : <Button variant="text" onClick={() => onScanEntriesClicked()}>Run scan now</Button>
                }               
              </Typography>
            </div>
          )}
          {tournament && tournament.resources && tournament.resources.publicRoster && (
            <div className="mt-2">
              <Typography variant="body">
                Click <MUILink href={tournament.resources.publicRoster} target="_blank" rel="noreferrer">here</MUILink> for the publically available roster for the tournament.
              </Typography>
            </div>
          )}
          {tournament && tournament.resources && tournament.resources.entryForm && (
            <div className="mt-2">
              <Typography variant="body">
                Click <MUILink href={tournament.resources.entryForm} target="_blank" rel="noreferrer">here</MUILink> for the page to register and collect payment for the tournament.
              </Typography>
            </div>
          )}
        </React.Fragment>
      )} />
    </div>
  );
}

export default ManageTournamentsPage;