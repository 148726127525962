import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Unstable_Grid2 as Grid} from "@mui/material";
import { orderBy, set } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideCustomizeTournamentEntryDialog, putTournamentEntryCustomization } from "./tournamentsSlice";
import { entryIdNumber, entryName } from "./tournamentUtils";

const extraProperties = [
  { path: 'rating', label: 'Rating', tournamentEntryKey: 'rating' },
  { path: 'team', label: 'Team' },
];

const CustomizeTournamentEntryDialog = () => {
  const dispatch = useDispatch();
  const showCustomizeTournamentEntryDialog = useSelector(state => state.tournaments.showCustomizeTournamentEntryDialog);
  const tournamentEntry = useSelector(state => state.tournaments.tournamentEntryToCustomize);
  let [settings, setSettings] = useState([]);
  
  let dialogTitle = "...";
  if (tournamentEntry && tournamentEntry.tournament && tournamentEntry.tournament.commerce) {
    const { entry, tournament } = tournamentEntry;
    const name = entryName({ tournament, entry });
    const usChessId = entryIdNumber({ tournament, entry });
    const usChessUrl = `https://uschess.org/assets/msa_joomla/MbrDtlMain.php?${usChessId}`;
    dialogTitle = <div>{name} (<a href={usChessUrl} target="_blank" rel="noreferrer">{usChessId}</a>)</div>
  }

  useEffect(() => {
    if (showCustomizeTournamentEntryDialog && tournamentEntry) {
      let initialSettings = [];
      initialSettings.push.apply(initialSettings, Object.keys(tournamentEntry.entry.properties).map((propertyKey) => ({
        settingName: propertyKey,
        settingPath: `properties.${propertyKey}`,
        entryValue: tournamentEntry.entry.properties[propertyKey],
        customizedValue: (tournamentEntry.entry.customization && tournamentEntry.entry.customization.properties && tournamentEntry.entry.customization.properties[propertyKey]) || '',
      })));
      initialSettings.push.apply(initialSettings, extraProperties.map(({ path, label, tournamentEntryKey }) => ({
        settingName: label,
        settingPath: path,
        entryValue: tournamentEntryKey ? tournamentEntry[tournamentEntryKey] : 'N/A',
        customizedValue: (tournamentEntry.entry.customization && tournamentEntry.entry.customization[path]) || '',
      })));
      if (tournamentEntry.group && !tournamentEntry.group.isDefaultGroup) {
        initialSettings.push({
          settingName: 'Group Order',
          settingPath: 'groupOrder',
          entryValue: '',
          customizedValue: (tournamentEntry.entry.customization && tournamentEntry.entry.customization.groupOrder) || '',
        });
      }
      initialSettings = orderBy(initialSettings, 'settingName');
      setSettings(initialSettings);
    }
  }, [showCustomizeTournamentEntryDialog, tournamentEntry]);
  
  const onCustomizationChanged = (settingName) => (event) => {
    const newSettings = settings.map((setting) => {
      if (setting.settingName === settingName) {
        return { ...setting, customizedValue: event.target.value };
      }
      return setting;
    })
    setSettings(newSettings);
  };

  const putCustomizationAndClose = (extras = {}) => {
    const { academyId, tournamentId } = tournamentEntry.tournament;
    const { entryId, customization: originalCustomization = {} } = tournamentEntry.entry;
    const customization = settings.reduce((cur, next) => {
      const { settingPath, customizedValue } = next;
      if (customizedValue.length) {
        set(cur, settingPath, customizedValue);
      }
      return cur;
    }, { properties: {}, ...extras });
    // Don't modify the section if entry was assigned to one
    customization.section = originalCustomization.section;
    dispatch(putTournamentEntryCustomization({ academyId, tournamentId, entryId, customization })).then(() => {
      dispatch(hideCustomizeTournamentEntryDialog());
    });
  }

  const handleActivateEntryClicked = (activate) => () => {
    const { entry, tournament } = tournamentEntry;
    if (activate || window.confirm(`Are you sure you want to deactivate ${entryName({ tournament, entry })}?`)) {
      const extras = {};
      if (!activate) {
        extras.deactivated = true;
      }
      putCustomizationAndClose(extras);
    }
  };

  const handleUpdateClicked = () => putCustomizationAndClose();

  const isDeactivated = tournamentEntry && tournamentEntry.entry.customization && tournamentEntry.entry.customization.deactivated === true;

  return (
    <Dialog open={!!showCustomizeTournamentEntryDialog}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}></Grid>
          {settings.map(({ settingName, settingPath, entryValue, customizedValue }) => (
            <React.Fragment key={settingPath}>
              <Grid xs={6}>
                <TextField disabled label={settingName} variant="outlined" value={entryValue} InputProps={{ readOnly: true }}/>
              </Grid>
              <Grid xs={6}>
                <TextField label={settingName} variant="outlined" value={customizedValue} onChange={onCustomizationChanged(settingName)}/>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        { isDeactivated && <Button onClick={handleActivateEntryClicked(true)} variant="outlined" color="success">Activate Entry</Button>}
        { !isDeactivated && <Button onClick={handleActivateEntryClicked(false)} variant="outlined" color="error">Deactivate Entry</Button>}
        <Button onClick={() => dispatch(hideCustomizeTournamentEntryDialog())}>Close</Button>
        <Button onClick={handleUpdateClicked}>Update</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomizeTournamentEntryDialog;