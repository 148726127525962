import { createSlice } from "@reduxjs/toolkit";

const luckyChessSpinnersForVariant = {
  hyper: 2,
  turbo: 2,
}

const funSlice = createSlice({
  name: 'fun',
  initialState: {
    luckyChessVariant: 'standard',
    luckyChessActiveSpinnerIndex: 0,
    luckyChessSideToMove: 'White',
    luckyChessFirstSpin: true,
    luckyChessFirstMove: false,
  },
  reducers: {
    setLuckyChessVariant: (state, action) => {
      state.luckyChessVariant = action.payload;
      state.luckyChessActiveSpinnerIndex = 0;
      state.luckyChessFirstSpin = true;
      state.luckyChessSideToMove = 'White';
    },
    startOrStopSpinner: (state) => {
      if (state.luckyChessActiveSpinnerIndex === 0 && !state.luckyChessFirstSpin) {
        state.luckyChessSideToMove = state.luckyChessSideToMove === 'White' ? 'Black' : 'White';
      }
      state.luckyChessActiveSpinnerIndex += 1;
      const maxSpinners = luckyChessSpinnersForVariant[state.luckyChessVariant] || 1;      
      if (state.luckyChessActiveSpinnerIndex > maxSpinners) {
        state.luckyChessActiveSpinnerIndex = 0;        
      }
      state.luckyChessFirstSpin = false;
    },
    toggleFirstMoveChanged: (state) => {
      state.luckyChessFirstMove = !state.luckyChessFirstMove;
    },
  },
});

export default funSlice.reducer;

export const {
  setLuckyChessVariant,
  startOrStopSpinner,
  toggleFirstMoveChanged,
} = funSlice.actions;