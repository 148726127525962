import React from 'react'
import { useStore } from 'react-redux';
import ErrorAlert from './ErrorAlert';

export const Spinner = ({
  text = '',
  size = '5em',
  thunks = [],
  errorMessage,
  isLoadingFn = () => false,
  idleComponentFn = () => null,
  componentFn = () => null
}) => {
  const store = useStore();
  
  let showError = false;
  let showIdle = false;
  let showSpinner = false;  
  
  if (thunks) {
    const thunksArray = Array.isArray(thunks) ? thunks : [thunks];
    thunksArray.some((thunkArrayElement) => {
      let thunk, index;
      if (thunkArrayElement.thunk !== undefined) {
        thunk = thunkArrayElement.thunk;
        index = thunkArrayElement.index;
      } else {
        thunk = thunkArrayElement;
      }
      const { typePrefix } = thunk;
      const [reducerName, fieldName] = typePrefix.split('/');
      const statusValue = store.getState()[reducerName][`${fieldName}Status`];
      let status;
      if (index) {
        status = statusValue[index];
      } else {
        status = statusValue;
      }
      if (status === 'failed') {
        showError = true;
      } else if (status === 'idle') {
        showIdle = true;
      } else if (!status || status === 'loading') {
        showSpinner = true;
      }
      return showError;
    });
  }

  showSpinner = showSpinner || isLoadingFn();
  
  const header = text ? <h4>{text}</h4> : null
  if (showError) {
    return <ErrorAlert message={errorMessage} />;
  } else if (showSpinner) {
    return (
      <div className="spinner">
        {header}
        <div className="loader" style={{ height: size, width: size }} />
      </div>
    );
  } else if (showIdle) {
    return idleComponentFn();
  } else {
    return componentFn();
  }
}
