import get from 'lodash.get';
import moment from 'moment';

export const dateText = (tournament) => {
  const dateStr = (d) => moment(d).format('MM/DD/YY');
  const startDateStr = dateStr(tournament.startDate);
  const endDateStr = dateStr(tournament.endDate);
  return startDateStr === endDateStr ? startDateStr : `${startDateStr} - ${endDateStr}`;
};

const entryProperty = ({ tournament, entry, comerceEntryPropertyName, defaultValue = '...' }) => {
  if (tournament && tournament.commerce && tournament.commerce.entryProperties) {
    const propertyName = tournament.commerce.entryProperties[comerceEntryPropertyName];
    return get(
      entry.customization || { properties: {} },
      `properties.${propertyName}`,
      entry.properties[propertyName]
    );
  } else {
    return defaultValue;
  }
}

export const entryName = ({ tournament, entry }) => entryProperty({
  tournament,
  entry,
  comerceEntryPropertyName: 'nameProperty',
});

export const entryIdNumber = ({ tournament, entry }) => entryProperty({
  tournament,
  entry,
  comerceEntryPropertyName: 'uschessIdProperty',
});

export const entryUSChessIdNumber = ({ tournament, entry }) => entryProperty({
  tournament,
  entry,
  comerceEntryPropertyName: 'uschessIdProperty',
});

export const entryNWSRSIdNumber = ({ tournament, entry }) => entryProperty({
  tournament,
  entry,
  comerceEntryPropertyName: 'nwsrsIdProperty',
});

export const entryEmail = ({ tournament, entry }) => entryProperty({
  tournament,
  entry,
  comerceEntryPropertyName: 'emailProperty',
  defaultValue: '',
});

export const entryCreationDate = ({ entry }) => (entry.orderMetadata || {}).createdOn || '';