import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Select } from 'formik-mui';
import { hideEditTournamentEntrySettingsDialog, updateTournament } from './tournamentsSlice';

const entryProperties = {
  nameProperty: { label: 'Name property' },
  emailProperty: { label: 'Email property' },
  uschessIdProperty: { label: 'US Chess ID property' },
  nwsrsIdProperty: { label: 'NWSRS ID property' },
  gradeProperty: { label: 'Grade property' },
};

const EditTournamentEntrySettingsDialog = () => {
  const dispatch = useDispatch()

  const showEditTournamentEntrySettingsDialog = useSelector(state => state.tournaments.showEditTournamentEntrySettingsDialog);
  const tournamentToEdit = useSelector(state => state.tournaments.tournamentToEdit);
  const tournamentEntries = useSelector(state => state.tournaments.tournamentEntries);
  const updateTournamentStatus = useSelector(state => state.tournaments.updateTournamentStatus)

  const { academyId, tournamentId } = tournamentToEdit || {};
  const entries = tournamentEntries[`${academyId}::${tournamentId}`] || [];
  const firstEntry = entries[0] || { properties: {} };
  const firstEntryPropertyNames = Object.keys(firstEntry.properties).sort();

  const [initialValues, setInitialValues] = useState(null);

  const handleCancel = () => {
    dispatch(hideEditTournamentEntrySettingsDialog());
  }

  useEffect(() => {
    let initialValues = null;
    if (showEditTournamentEntrySettingsDialog && tournamentToEdit) {
      initialValues = {};
      const { commerce = { entryProperties: {}} } = tournamentToEdit
      const { entryProperties = {} } = commerce;
      Object.keys(entryProperties).forEach((id) => {
        initialValues[id] = entryProperties[id] || '';
      });
    }
    setInitialValues(initialValues);
  }, [showEditTournamentEntrySettingsDialog, tournamentToEdit]);

  const { requiredEntryPropertyNames = [] } = (tournamentToEdit || { commerce: {} }).commerce;

  return (
    <Dialog open={!!showEditTournamentEntrySettingsDialog}>
      <DialogTitle>Edit Entry Settings</DialogTitle>
      <DialogContent>
        {initialValues && showEditTournamentEntrySettingsDialog && (<Formik
          initialValues = {initialValues}
          validate={(values => ({}))}
          onSubmit = {(values, { setSubmitting }) => {              
            const { academyId, tournamentId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, ratingsSystems, commerce, sections, instructions } = tournamentToEdit;
            const entryProperties = values;
            const tournament = { academyId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, ratingsSystems, instructions, commerce: { ...commerce, entryProperties }, sections };
            dispatch(updateTournament({ academyId, tournamentId, tournament })).finally(() => {
              setSubmitting(false);
            });
          }}
        >
          {({ submitForm }) => (
            <Form>                
              { updateTournamentStatus === 'failed' && (
                <Alert severity="error">Update failed. Please reach out to technical support.</Alert>
              )}
              {requiredEntryPropertyNames.map((id) => (
                <React.Fragment key={id}>
                  <Field
                    component={Select}
                    formControl={{ fullWidth: true, variant: 'standard' }}
                    inputLabel={{ variant: 'standard' }}
                    fullWidth
                    id={id}
                    name={id}
                    label={entryProperties[id].label}
                  >
                    <MenuItem key = "none" value={''}>N/A</MenuItem>
                    {firstEntryPropertyNames.map((propertyName) => (
                      <MenuItem key={propertyName} value={propertyName}>{propertyName}</MenuItem>
                    ))}
                  </Field>
                  <br />
                  <div className="mt-2" />
                </React.Fragment>
              ))}
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={submitForm}>Update</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>)}
      </DialogContent>      
    </Dialog>
  )
}

export default EditTournamentEntrySettingsDialog