import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from "@mui/material";
import orderBy from "lodash.orderby";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideViewAttributesDialog, setViewAttributes } from "./tournamentsSlice";

const ViewEntryAttributesDialog = () => {
  const dispatch = useDispatch();
  const [attributes, setAttributes] = useState([]);
  const showViewAttributesDialog = useSelector(state => state.tournaments.showViewAttributesDialog);
  const tournament = useSelector(state => state.tournaments.tournamentToConfigure);
  const tournamentEntries = useSelector(state => state.tournaments.tournamentEntries);
  const viewAttributes = useSelector(state => state.tournaments.viewAttributes);

  useEffect(() => {
    if (showViewAttributesDialog && tournament) {
      const entriesKey = `${tournament.academyId}::${tournament.tournamentId}`;
      const initialChecked = viewAttributes[entriesKey].reduce((a, c) => Object.assign(a, {[c]: true}), {});
      if (tournamentEntries && tournamentEntries[entriesKey] && tournamentEntries[entriesKey].length) {
        let initialAttributes = [];
        const tournamentEntry = tournamentEntries[entriesKey][0];
        initialAttributes.push.apply(initialAttributes, Object.keys(tournamentEntry.properties).map((propertyKey) => ({
          attributeName: propertyKey,
          attributePath: `properties.${propertyKey}`,
          checked: initialChecked[`properties.${propertyKey}`] === true,
        })));
        initialAttributes = orderBy(initialAttributes, 'attributeName');

        const ratingSystems = (tournament.ratingsSystems || []).reduce((a, c) => Object.assign(a, {[c]: true}), {});
        if (ratingSystems.nwsrs) {
          initialAttributes.splice(0, 0, {
            attributeName: 'NWSRS ID/Grade/Link',
            attributePath: 'nwsrs-id',
            checked: initialChecked['nwsrs-id'] === true,
          })
        }
        if (ratingSystems.uschess) {
          initialAttributes.splice(0, 0, {
            attributeName: 'US Chess ID/Link',
            attributePath: 'uschess-id',
            checked: initialChecked['uschess-id'] === true,
          })
        }

        setAttributes(initialAttributes);
      }
    }
  }, [showViewAttributesDialog, viewAttributes, tournament, tournamentEntries]);

  const onAttributeChecked = (attributePath) => () => {
    const newAttributes = attributes.map((attribute) => {
      if (attribute.attributePath === attributePath) {
        return { ...attribute, checked: !attribute.checked };
      }
      return attribute;
    })
    setAttributes(newAttributes);
  };

  const handleOKClicked = () => {
    const { academyId, tournamentId } = tournament;
    const viewAttributes = attributes
      .filter(({ checked }) => !!checked)
      .map(({ attributePath }) => attributePath);
    dispatch(setViewAttributes({ academyId, tournamentId, viewAttributes }));
    dispatch(hideViewAttributesDialog());
  }

  return (
    <Dialog open={!!showViewAttributesDialog}>
      <DialogTitle>View/Hide Attributes</DialogTitle>
      <DialogContent> 
        <FormGroup>
          {attributes.map(({ attributeName, attributePath, checked }) => (
            <FormControlLabel
              key={attributePath}
              control={<Checkbox
                checked={checked}
                onChange={onAttributeChecked(attributePath)}                
              />}
              label={attributeName}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideViewAttributesDialog())}>Cancel</Button>
        <Button onClick={handleOKClicked}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewEntryAttributesDialog;