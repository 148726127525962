import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { createUser } from './usersSlice';

const RegisterUserDialog = ({ handleLogOut }) => {
  const dispatch = useDispatch()

  const show = useSelector(state => state.users.showRegisterUserDialog)
  const emailAddress = useSelector(state => state.session.emailAddress)
  const firstName = useSelector(state => state.session.firstName)
  const lastName = useSelector(state => state.session.lastName)
  const createUserFailedMessage = useSelector(state => state.users.createUserFailedMessage)
  const createUserStatus = useSelector(state => state.users.createUserStatus)

  const handleCancel = () => {
    handleLogOut(dispatch)
  }

  return (
    <Dialog open={show}>
      <DialogTitle>Register</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Welcome! Please provide a little information about yourself to get started. You will not be charged for anything until you set up an academy and opt in to specific features.</Typography>
        <Formik
          initialValues={{
            email: emailAddress,
            name: `${firstName} ${lastName}`
          }}
          validate={(values => {
            const errors = {}
            if (!values.name) {
              errors.name = 'Required'
            }
            return errors
          })}
          onSubmit = {(values, { setSubmitting }) => {
            const { email: emailAddress, name } = values
            dispatch(createUser({ emailAddress, name })).finally(() => setSubmitting(false))
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email"
                InputProps={{
                  readOnly: true,
                }}
                disabled
                margin="normal"
              />
              <br />
              <Field
                component={TextField}
                name="name"
                label="Name"
                margin="normal"
              />
              <br />
              { createUserStatus === 'failed' && (
                <Alert severity="error">User creation failed: {createUserFailedMessage}</Alert>
              )}
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={submitForm}>Register</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>      
    </Dialog>
  )
}

export default RegisterUserDialog