import manImg from './man.jpg';

const DashboardPage = () => {
  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <div className="absolute inset-0 bg-[url(./grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      <div className="relative bg-white px-6 pt-4 pb-4 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 md:max-w-2xl">
        <div className="mx-auto max-w-lg">
          <div className="font-extrabold text-2xl sm:text-3xl font-[roboto] text-center mb-3"><h2>⚠️&nbsp;Under Construction&nbsp;⚠️</h2></div>
          <img src={manImg} className="ring-1 shadow-lg ring-amber-100" alt="Coming Soon!" />
          <div className="mt-2 text-sm sm:text-base">
            <p className="font-semibold text-center">Chess lessons, tournaments and camps for <span className="font-bold text-blue-600">YOUR</span> academy or club.</p>
            <p className="font-semibold text-center mt-1">We're not ready yet, but we're making moves.</p>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default DashboardPage;