import { Add, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import some from 'lodash.some';
import findIndex from 'lodash.findindex';
import { hideManageTournamentSectionsDialog, updateTournament } from "./tournamentsSlice";
import { fetchAcademyCommerce } from "../academies/academiesSlice";
import { Spinner } from "../../components/Spinner";

const ratingTypes = {
  'Regular': 'regular',
  'Quick': 'quick',
  'Blitz': 'blitz',
  'Online Regular': 'onlineRegular',
  'Online Quick': 'onlineQuick',
  'Online Blitz': 'onlineBlitz',
};

const ManageTournamentSectionsDialog = () => {
  const dispatch = useDispatch();
  const academyCommerce = useSelector((state) => state.academies.academyCommerce);
  const showManageTournamentSectionsDialog = useSelector((state) => state.tournaments.showManageTournamentSectionsDialog);
  const tournamentToEdit = useSelector(state => state.tournaments.tournamentToEdit);
  let [tournamentSections, setTournamentSections] = useState(null);
  let [newSectionName, setNewSectionName] = useState('');
  let [selectedSection, setSelectedSection] = useState(null);
  let [selectedSectionProductId, setSelectedSectionProductId] = useState('');
  let [selectedSectionGroupingOptions, setSelectedSectionGroupingOptions] = useState([]);
  let [selectedSectionPrimaryRating, setSelectedSectionPrimaryRating] = useState('');
  let [selectedSectionSecondaryRating, setSelectedSectionSecondaryRating] = useState('');
  let [selectedSectionTimeControl, setSelectedSectionTimeControl] = useState('');
  let [selectedSectionAdditionalDisplayFields, setSelectedSectionAdditionalDisplayFields] = useState('');
  let [selectedSectionGroupingFields, setSelectedSectionGroupingFields] = useState('');
  let [selectedSectionSortingFields, setSelectedSectionSortingFields] = useState('');
  let [selectedSectionIsCopiedToReplica, setSelectedSectionIsCopiedToReplica] = useState(false);
  let [externalProductNames, setExternalProductNames] = useState({});

  useEffect(() => {
    if (showManageTournamentSectionsDialog && tournamentToEdit) {
      if (academyCommerce[tournamentToEdit.academyId]) {
        setTournamentSections((tournamentToEdit.sections || []).map(({ name, order, conditions, ratingTypes = [], additionalDisplayFields = '', groupingFields = '', sortingFields = '', timeControl = '', isCopiedToReplica = false, }) => {
          let externalProductVariantId = '';
          
          const groupingOptionsByField = {};
          const groupingOptions = [];
          tournamentToEdit.groupingOptions.forEach(({ type, path, entryProperty }, idx) => {
            let id;
            switch (type) {
              case 'rating':
                id = type;
                groupingOptionsByField[id] = { type, minimumRating: '', maximumRating: '' };
                break;
              case 'minMax':
                id = path || `properties.${tournamentToEdit.commerce.entryProperties[entryProperty]}`;
                groupingOptionsByField[id] = { type, minimumValue: '', maximumValue: '' };
                break;
              default:
                id = path || `properties.${tournamentToEdit.commerce.entryProperties[entryProperty]}`;
                groupingOptionsByField[id] = {};
                break;
            }
            groupingOptions.push(groupingOptionsByField[id]);
          });

          conditions.forEach(({ field, comparator, value }) => {
            if (field === 'orderMetadata.externalProductId') {
              externalProductVariantId = value;
            } else if (field === 'rating' && groupingOptionsByField.rating) {
              groupingOptionsByField.rating[comparator === 'GTE' ? 'minimumRating' : 'maximumRating'] = value.toString();
            } else if (groupingOptionsByField[field] && groupingOptionsByField[field].type === 'minMax') {
              groupingOptionsByField[field][comparator === 'GTE' ? 'minimumValue' : 'maximumValue'] = value.toString();
            }
          });
          return {
            name,
            order,
            ratingTypes: {
              primary: ratingTypes.length > 0 ? ratingTypes[0] : '',
              secondary: ratingTypes.length > 1 ? ratingTypes[1] : '',
            },
            additionalDisplayFields,
            groupingFields,
            sortingFields,
            timeControl,
            externalProductVariantId,
            groupingOptions,
            isCopiedToReplica,
          };
        }));
        setNewSectionName('');
        setSelectedSection(null);
        setExternalProductNames(academyCommerce[tournamentToEdit.academyId].externalProducts.reduce((cur, next) => {
          cur[next.id] = next.name;
          return cur;
        }, {}));
      } else {
        dispatch(fetchAcademyCommerce(tournamentToEdit.academyId));
      }
    } else {
      setTournamentSections(null);
    }
  }, [academyCommerce, dispatch, showManageTournamentSectionsDialog, tournamentToEdit]);

  const handleAddSectionClicked = () => {
    if (newSectionName.trim().length > 0 && !some(tournamentSections, ({ name }) => name.trim() === newSectionName.trim())) {
      tournamentSections.push({
        name: newSectionName,
        order: tournamentSections.length,
        ratingTypes: {
          primary: tournamentToEdit.defaultRatingType || undefined,
        },
        conditions: [],
        externalProductVariantId: '',
        groupingOptions: tournamentToEdit.groupingOptions.map(({ type }) => {
          switch (type) {
            case 'rating': return { minimumRating: '', maximumRating: '' };
            case 'minMax': return { minimumValue: '', maximumValue: '' };
            default: return {};
          }
        }),
        isCopiedToReplica: false,
      });
      setNewSectionName('');
    }
  };

  const handleMoveUpClicked = () => {
    const selectedSectionIdx = findIndex(tournamentSections, ({ order }) => order === selectedSection.order);
    if (selectedSectionIdx > 0) {
      setTournamentSections((tournamentSections) => tournamentSections.map((section, idx) => {
        if (idx === selectedSectionIdx-1) {
          const newSelectedSection = ({ ...tournamentSections[selectedSectionIdx], ...{ order: idx }});
          setSelectedSection(newSelectedSection);
          return newSelectedSection;
        } else if (idx === selectedSectionIdx) {
          return ({ ...tournamentSections[selectedSectionIdx-1], ...{ order: idx }});
        } else {
          return {...section};
        }
      }));
    }
  };

  const handleMoveDownClicked = () => {
    const selectedSectionIdx = findIndex(tournamentSections, ({ order }) => order === selectedSection.order);
    if (selectedSectionIdx < tournamentSections.length-1) {
      setTournamentSections((tournamentSections) => tournamentSections.map((section, idx) => {
        if (idx === selectedSectionIdx+1) {
          const newSelectedSection = ({ ...tournamentSections[selectedSectionIdx], ...{ order: idx }});
          setSelectedSection(newSelectedSection);
          return newSelectedSection;
        } else if (idx === selectedSectionIdx) {
          return ({ ...tournamentSections[selectedSectionIdx+1], ...{ order: idx }});
        } else {
          return {...section};
        }
      }));
    }
  };

  const handleDeleteSectionClicked = () => {
    const selectedSectionIdx = findIndex(tournamentSections, ({ order }) => order === selectedSection.order);
    if (selectedSectionIdx >= 0 && window.confirm(`Are you sure you want to delete section ${selectedSection.name}?`)) {
      setTournamentSections((tournamentSections) => tournamentSections
        .map((section, idx) => {
          if (idx < selectedSectionIdx) {
            return {...section};
          } else if (idx === selectedSectionIdx) {
            return null;
          } else {
            return {...section, ...{ order: section.order-1 }};
          }
        })
        .filter(e => !!e)
      );
      setSelectedSection(null);
    }
  };

  const handleSectionClicked = (section) => {
    setSelectedSection(section);
    setSelectedSectionProductId('');
    setSelectedSectionProductId(section.externalProductVariantId);
    setSelectedSectionPrimaryRating(section.ratingTypes.primary || '');
    setSelectedSectionSecondaryRating(section.ratingTypes.secondary || '');
    setSelectedSectionTimeControl(section.timeControl || '');
    setSelectedSectionAdditionalDisplayFields(section.additionalDisplayFields || '');
    setSelectedSectionGroupingFields(section.groupingFields || '');
    setSelectedSectionSortingFields(section.sortingFields || '');
    setSelectedSectionGroupingOptions(section.groupingOptions);
    setSelectedSectionIsCopiedToReplica(section.isCopiedToReplica);
  };

  const handleProductIdChanged = (event) => {
    setSelectedSectionProductId(event.target.value);
    selectedSection.externalProductVariantId = event.target.value;
  };

  const handlePrimaryRatingChanged = (event) => {
    setSelectedSectionPrimaryRating(event.target.value);
    selectedSection.ratingTypes.primary = event.target.value;
  }

  const handleSecondaryRatingChanged = (event) => {
    setSelectedSectionSecondaryRating(event.target.value);
    selectedSection.ratingTypes.secondary = event.target.value;
  }

  const handleTimeControlChanged = (event) => {
    setSelectedSectionTimeControl(event.target.value);
    selectedSection.timeControl = event.target.value;
  }

  const handleAdditionalDisplayFieldsChanged = (event) => {
    setSelectedSectionAdditionalDisplayFields(event.target.value);
    selectedSection.additionalDisplayFields = event.target.value;
  }

  const handleGroupingFieldsChanged = (event) => {
    setSelectedSectionGroupingFields(event.target.value);
    selectedSection.groupingFields = event.target.value;
  }

  const handleSortingFieldsChanged = (event) => {
    setSelectedSectionSortingFields(event.target.value);
    selectedSection.sortingFields = event.target.value;
  }

  const handleIsCopiedToReplicaChanged = (event) => {
    setSelectedSectionIsCopiedToReplica(event.target.checked);
    selectedSection.isCopiedToReplica = event.target.checked;
  }

  const handleGroupOptionChanged = (groupOptionIdx, fieldName) => (event) => {
    const groupingOptions = selectedSectionGroupingOptions.map((option, idx) => {
      if (idx === groupOptionIdx) {
        return { ...option, ...{ [fieldName]: event.target.value } };
      } else {
        return option;
      }
    });
    setSelectedSectionGroupingOptions(groupingOptions);
    selectedSection.groupingOptions = groupingOptions;
  }

  const handleUpdateClicked = () => {
    const { academyId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, commerce, ratingsSystems, instructions } = tournamentToEdit;
    const mapSection = ({ name, order, externalProductVariantId, groupingOptions, ratingTypes, timeControl, additionalDisplayFields, groupingFields, sortingFields, isCopiedToReplica, }) => {
      const conditions = [];
      if (externalProductVariantId) {
        conditions.push({ field: 'orderMetadata.externalProductId', comparator: 'EQ', value: externalProductVariantId });
      }
      tournamentToEdit.groupingOptions.map(({ type, path, entryProperty }, groupingOptionsIdx) => {
        switch (type) {
          case 'rating':
            const { minimumRating, maximumRating } = groupingOptions[groupingOptionsIdx];
            if (minimumRating && minimumRating.trim().length && /[0-9]+/.test(minimumRating.trim())) {
              conditions.push({ field: 'rating', comparator: 'GTE', value: parseInt(minimumRating.trim()) });
            }
            if (maximumRating && maximumRating.trim().length && /[0-9]+/.test(maximumRating.trim())) {
              conditions.push({ field: 'rating', comparator: 'LTE', value: parseInt(maximumRating.trim()) });
            }
            break;
          case 'minMax':
            const { minimumValue, maximumValue } = groupingOptions[groupingOptionsIdx];
            let field;
            if (path) {
              field = path;
            } else if (entryProperty) {
              field = `properties.${tournamentToEdit.commerce.entryProperties[entryProperty]}`
            }
            if (minimumValue) {
              conditions.push({ field, comparator: 'GTE', value: minimumValue.trim() });
            }
            if (maximumValue) {
              conditions.push({ field, comparator: 'LTE', value: maximumValue.trim() });
            }
            break;
          default:
            break;
        }
      });
      const section = { name, order, conditions, ratingTypes: [], timeControl, additionalDisplayFields, groupingFields, sortingFields, isCopiedToReplica, };
      if (ratingTypes.primary !== '') {
        section.ratingTypes.push(ratingTypes.primary);
      }
      if (ratingTypes.secondary !== '') {
        section.ratingTypes.push(ratingTypes.secondary);
      }
      return section;
    }
    const tournament = { academyId, name, publishRoster, defaultRatingType, startDate, endDate, timezone, commerce, ratingsSystems, instructions, sections: tournamentSections.map(mapSection) };
    const { tournamentId } = tournamentToEdit;
    dispatch(updateTournament({ academyId, tournamentId, tournament })).then(() => {
      dispatch(hideManageTournamentSectionsDialog());
    });
  }

  return (
    <Dialog open={!!showManageTournamentSectionsDialog}>
      <DialogTitle>{(tournamentToEdit || { name: 'Manage' }).name} Sections</DialogTitle>
      <DialogContent sx={{ minWidth: '26em' }}>
        <Spinner isLoadingFn={() => tournamentSections === null} componentFn={() => (
          <React.Fragment>
            <Divider textAlign="left">Sections ({tournamentSections.length})</Divider>
            <div className="relative">
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel htmlFor="add-section-input">Name</InputLabel>              
                  <OutlinedInput
                    id="add-section-input"
                    type="text"
                    value={newSectionName}
                    onChange={(event) => setNewSectionName(event.target.value)}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        handleAddSectionClicked();
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="add section"
                          onClick={handleAddSectionClicked}
                          edge="end"
                        >
                          <Add />                  
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Section"
                  />
              </FormControl>
              <div className="absolute top-0 right-0">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Toolbar disableGutters={true}>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="Move Up"
                      onClick={handleMoveUpClicked}
                      color="inherit"
                      disabled={!selectedSection || selectedSection.order === 0}
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="Move Down"
                      onClick={handleMoveDownClicked}
                      color="inherit"
                      disabled={!selectedSection || selectedSection.order === tournamentSections.length-1}
                    >
                      <ArrowDownward />
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="Delete Section"
                      onClick={handleDeleteSectionClicked}
                      color="inherit"
                      disabled={!selectedSection}
                    >
                      <Delete />
                    </IconButton>
                  </Toolbar>
                </Box>
              </div>
            </div>
            <List>
              {tournamentSections.map((section) => (
                <ListItem disablePadding key={section.name}>
                  <ListItemButton
                    selected={selectedSection && selectedSection.name === section.name}
                    onClick={() => handleSectionClicked(section)}
                  >
                    <ListItemText primary={section.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )} />
        {selectedSection !== null && <React.Fragment>
          <Divider textAlign="left">{selectedSection.name}</Divider>
          <FormControl fullWidth margin="dense">
            <InputLabel id="select-product-id">External product variant</InputLabel>
            <Select
              labelId="select-product-id"
              value={selectedSectionProductId}
              label="External product variant"
              onChange={handleProductIdChanged}
            >
              <MenuItem value="">N/A</MenuItem>
              {tournamentToEdit && tournamentToEdit.commerce.externalProducts.map((productId) => (
                <MenuItem key={productId} value={productId}>{externalProductNames[productId]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {tournamentToEdit && tournamentToEdit.groupingOptions.map(({ id, type, label, path, values }, groupingOptionIdx) => {
            switch (type) {
              case 'rating':
                return <div key={`${type}::${id}`}>
                  <FormControl margin="dense">
                    <InputLabel htmlFor="input-rating-min">Minimum Rating</InputLabel>
                    <OutlinedInput
                      id="input-rating-min"
                      value={selectedSectionGroupingOptions[groupingOptionIdx].minimumRating}
                      label="Minimum Rating"
                      onChange={handleGroupOptionChanged(groupingOptionIdx, 'minimumRating')}
                    />
                  </FormControl>
                  <span className="mr-2"></span>
                  <FormControl margin="dense">
                    <InputLabel htmlFor="input-rating-max">Maximum Rating</InputLabel>
                    <OutlinedInput
                      id="input-rating-max"
                      value={selectedSectionGroupingOptions[groupingOptionIdx].maximumRating}
                      label="Maximum Rating"
                      onChange={handleGroupOptionChanged(groupingOptionIdx, 'maximumRating')}
                    />
                  </FormControl>
                </div>;
              case 'minMax':
                return <div key={`${type}::${id}`}>
                  <FormControl margin="dense" sx={{ width: '210px' }}>
                    <InputLabel id={`input-${type}-${id}-min`}>{`Minimum ${label}`}</InputLabel>
                    <Select
                      labelId={`input-${type}-${id}-min`}
                      value={selectedSectionGroupingOptions[groupingOptionIdx].minimumValue}
                      label={`Minimum ${label}`}
                      onChange={handleGroupOptionChanged(groupingOptionIdx, 'minimumValue')}
                    >
                      <MenuItem value="">N/A</MenuItem>
                      {values.map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className="mr-2"></span>
                  <FormControl margin="dense" sx={{ width: '210px' }}>
                    <InputLabel id={`input-${type}-${id}-max`}>{`Maximum ${label}`}</InputLabel>
                    <Select
                      labelId={`input-${type}-${id}-max`}
                      value={selectedSectionGroupingOptions[groupingOptionIdx].maximumValue}
                      label={`Minimum ${label}`}
                      onChange={handleGroupOptionChanged(groupingOptionIdx, 'maximumValue')}
                    >
                      <MenuItem value="">N/A</MenuItem>
                      {values.map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>;
              default:
                return null;
            }
          })}
          <FormControl fullWidth margin="dense">
            <InputLabel id="select-primary-rating">Primary rating type</InputLabel>
            <Select
              labelId="select-primary-rating"
              value={selectedSectionPrimaryRating}
              label="Primary rating type"
              onChange={handlePrimaryRatingChanged}
            >
              <MenuItem value="">N/A</MenuItem>
              {tournamentToEdit && tournamentToEdit.availableRatingTypes.map(({ path, label }) => (
                <MenuItem key={path} value={path}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="select-secondary-rating">Secondary rating type</InputLabel>
            <Select
              labelId="select-secondary-rating"
              value={selectedSectionSecondaryRating}
              label="Secondary rating type"
              onChange={handleSecondaryRatingChanged}
            >
              <MenuItem value="">N/A</MenuItem>
              {tournamentToEdit && tournamentToEdit.availableRatingTypes.map(({ path, label }) => (
                <MenuItem key={path} value={path}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="input-time-control">Time Control</InputLabel>
            <OutlinedInput
              id="input-time-control"
              value={selectedSectionTimeControl}
              label="Time Control"
              onChange={handleTimeControlChanged}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="input-additional-display-fields">Additional Display Fields</InputLabel>
            <OutlinedInput
              id="input-additional-display-fields"
              value={selectedSectionAdditionalDisplayFields}
              label="Additional Display Fields"
              onChange={handleAdditionalDisplayFieldsChanged}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="input-grouping-fields">Entry Grouping Field (eg. "team")</InputLabel>
            <OutlinedInput
              id="input-grouping-fields"
              value={selectedSectionGroupingFields}
              label={'Entry Grouping Field (eg. "team")'}
              onChange={handleGroupingFieldsChanged}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="input-sorting-fields">Entry Sorting Field (defaults to "rating")</InputLabel>
            <OutlinedInput
              id="input-sorting-fields"
              value={selectedSectionSortingFields}
              label={'Entry Sorting Field (defaults to "rating")'}
              onChange={handleSortingFieldsChanged}
            />
          </FormControl>
          <FormControlLabel fullWidth margin="dense"
            control={<Checkbox
              checked={selectedSectionIsCopiedToReplica}
              onChange={handleIsCopiedToReplicaChanged}
            />}
            label="Copy section to tournament replicas"
          />
        </React.Fragment>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideManageTournamentSectionsDialog())}>Cancel</Button>
        <Button onClick={handleUpdateClicked}>Update</Button>
      </DialogActions>
    </Dialog>
  );  
}

export default ManageTournamentSectionsDialog;