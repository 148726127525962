import { useEffect, useState } from "react";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TournamentInstructionsEditor = ({ tournament, onChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText('')));

  useEffect(() => {
    setEditorState(EditorState.createWithContent(
      tournament.instructions
        ? ContentState.createFromBlockArray(htmlToDraft(tournament.instructions))
        : ContentState.createFromText('')
    ));
  }, [tournament.instructions]);

  const onEditorStateChange = (state) => {
    setEditorState(state);
  };
  const onContentStateChange = (content) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange(html);
  };
  return (
    <Editor
      editorState={editorState}
      // toolbarClassName="toolbarClassName"
      wrapperClassName="border-black border"
      editorClassName="p-2"
      onEditorStateChange={onEditorStateChange}
      onContentStateChange={onContentStateChange}
    />
  );
};

export default TournamentInstructionsEditor;
