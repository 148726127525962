import React, { useEffect } from 'react';
import { Alert, AlertTitle, Link, List, ListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listUserAcademies, showCreateAcademyDialog } from './academiesSlice';

const NoAcademiesAlert = () => {
  const dispatch = useDispatch();
  const listUserAcademiesStatus = useSelector(state => state.academies.listUserAcademiesStatus);
  const userAcademies = useSelector(state => state.academies.userAcademies);
  const userExists = useSelector(state => state.session.userExists);

  useEffect(() => {
    if (userExists) {
      dispatch(listUserAcademies());
    }
  }, [dispatch, userExists]);

  const onCreateAcademyClick = () => {
    dispatch(showCreateAcademyDialog());
  }

  return (
    <div>
      {listUserAcademiesStatus === 'succeeded' && userAcademies.length === 0  && (
        <Alert severity="info">
            <AlertTitle>No Academies Found</AlertTitle>
            We did not find any academies that you are allowed to manage.<br/>
            <List sx={{ listStyleType: 'disc', pl: 2}}>
              <ListItem sx={{ display: 'list-item' }}>To manage an academy or club that exists, contact someone who manages that academy and ask them to add you to the staff.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>To create a new academy, click <Link component="button" variant="body2" onClick={() => onCreateAcademyClick()}>here</Link>.</ListItem>
            </List>
        </Alert>
      )}
    </div>
  )

};

export default NoAcademiesAlert;