import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideImportTournamentResourceDialog, putTournamentResources } from "./tournamentsSlice";

const ImportTournamentResourceDialog = () => {
  const dispatch = useDispatch();
  
  const showImportTournamentResourceDialog = useSelector(state => state.tournaments.showImportTournamentResourceDialog);
  const tournamentToEdit = useSelector(state => state.tournaments.tournamentToEdit);

  const onPairingsSS11Changed = async (event) => {
    if (event.target.files[0]) {
      const resourceId = 'pairingsSS11';
      const resources = new FormData();
      resources.append(resourceId, event.target.files[0]);
      const { academyId, tournamentId } = tournamentToEdit;
      await dispatch(putTournamentResources({ academyId, tournamentId, resourceId, resources }));
      dispatch(hideImportTournamentResourceDialog());    
    }
  }

  return (
    <Dialog open={!!showImportTournamentResourceDialog}>
      <DialogTitle>Upload Tournament Resource</DialogTitle>
      <DialogContent>
        <input
          hidden
          accept="*.sjson"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={onPairingsSS11Changed}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Upload Pairings/Results .sjson
          </Button>
        </label> 

      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(hideImportTournamentResourceDialog())}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportTournamentResourceDialog;