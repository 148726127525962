import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { GoogleLogin } from '@leecheuk/react-google-login';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { deleteSession, hideLogInDialog, setInvalidSessionMessage, setUserExists } from './sessionSlice';
import { hideRegisterUserDialog, showRegisterUserDialog } from '../users/usersSlice';
import InvalidSessionDialog from './InvalidSessionDialog';

const LogInDialog = ({ clientId, handleCredentialSuccess, handleCredentialFailure, handleLogOut }) => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(state => state.session.isLoggedIn)
  const sessionId = useSelector(state => state.session.sessionId)
  const userExists = useSelector(state => state.session.userExists)
  const isEmailAllowed = useSelector(state => state.session.isEmailAllowed)
  const show = useSelector(state => state.session.showLogInDialog)
  const createUserStatus = useSelector(state => state.users.createUserStatus)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(hideLogInDialog())
      if (!userExists) {
        if (isEmailAllowed === false) {
          handleLogOut(dispatch)
          dispatch(setInvalidSessionMessage('Currently, access to Your Chess Academy is by invitation only. Please check back later.'))
        } else {
          if (createUserStatus === 'succeeded') {
            dispatch(hideRegisterUserDialog())
            dispatch(setUserExists(true))
          } else {
            dispatch(showRegisterUserDialog())
          }
        }
      }
    } else {
      if (sessionId) {
        dispatch(deleteSession())
      }
      dispatch(hideRegisterUserDialog())
    }
  }, [isLoggedIn, userExists, sessionId, createUserStatus, isEmailAllowed, handleLogOut, dispatch]);

  const handleClose = () => {
    dispatch(hideLogInDialog())
  }

  return (
    <div>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Log In</DialogTitle>
        <DialogContent>
          <GoogleLogin
            clientId={clientId}
            buttonText="Sign in with Google"
            onSuccess={handleCredentialSuccess}
            onFailure={handleCredentialFailure}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <InvalidSessionDialog />
    </div>
  )
}

export default LogInDialog