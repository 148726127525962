import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../api/client';

export const createUser = createAsyncThunk('users/createUser', (createUserInput, thunkApi) =>
  client(thunkApi).post('/v1/users', createUserInput, { withCredentials: true })
)

const usersSlice = createSlice({
  name: 'users',
  initialState: { createUserStatus: 'idle', showRegisterUserDialog: false },
  reducers: {
    showRegisterUserDialog(state) {
      state.showRegisterUserDialog = true
    },
    hideRegisterUserDialog(state) {
      state.showRegisterUserDialog = false
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createUser.pending, (state) => {
        state.createUserStatus = 'loading'
      })
      .addCase(createUser.rejected, (state, action) => {
        state.createUserStatus = 'failed'
        state.createUserFailedMessage = action.payload.data.message
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.createUserStatus = 'succeeded'
      })
  }
})

export const { showRegisterUserDialog, hideRegisterUserDialog } = usersSlice.actions

export default usersSlice.reducer
