import { createSlice, createAsyncThunk, createDraftSafeSelector } from '@reduxjs/toolkit'
import client from '../../api/client';

export const listTournaments = createAsyncThunk('tournaments/listTournaments', async (thunkApi) =>
  client(thunkApi).get('/v1/tournaments', { withCredentials: true }));

export const createTournament = createAsyncThunk('tournaments/createTournament', ({ academyId, tournament }, thunkApi) =>
  client(thunkApi).post(`/v1/academies/${academyId}/tournaments`, tournament, { withCredentials: true }));

export const updateTournament = createAsyncThunk('tournaments/updateTournament', ({ academyId, tournamentId, tournament }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/tournaments/${tournamentId}`, tournament, { withCredentials: true }));

export const deleteTournament = createAsyncThunk('tournaments/deleteTournament', ({ academyId, tournamentId }, thunkApi) =>
  client(thunkApi).delete(`/v1/academies/${academyId}/tournaments/${tournamentId}`, { withCredentials: true }));

export const fetchTournament = createAsyncThunk('tournaments/fetchTournament', ({ academyId, tournamentId }, thunkApi) =>
  client(thunkApi).get(`/v1/academies/${academyId}/tournaments/${tournamentId}`, { withCredentials: true }));

export const listTournamentEntries = createAsyncThunk('tournaments/listTournamentEntries', async ({ academyId, tournamentId }, thunkApi) =>
  client(thunkApi).get(`/v1/academies/${academyId}/tournaments/${tournamentId}/entries`, { withCredentials: true }));

export const putTournamentEntryCustomization = createAsyncThunk('tournaments/putTournamentEntryCustomization', async ({ academyId, tournamentId, entryId, customization }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/tournaments/${tournamentId}/entries/${entryId}/customization`, customization, { withCredentials: true }));

export const createTournamentEntry = createAsyncThunk('tournaments/createTournamentEntry', async ({ academyId, tournamentId, entry }, thunkApi) =>
  client(thunkApi).post(`/v1/academies/${academyId}/tournaments/${tournamentId}/entries`, entry, { withCredentials: true }));

export const scanTournament = createAsyncThunk('tournaments/scanForEntryOrders', async ({ academyId, tournamentId, scanRequest }, thunkApi) =>
  client(thunkApi).post(`/v1/academies/${academyId}/tournaments/${tournamentId}/scan`, scanRequest, { withCredentials: true }));

export const putTournamentResources = createAsyncThunk('tournaments/putTournamentResources', ({ academyId, tournamentId, resourceId, resources }, thunkApi) =>
  client(thunkApi).put(`/v1/academies/${academyId}/tournaments/${tournamentId}/resources/${resourceId}`, resources, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data'} }));

const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState: {
    tournaments: null,
    tournamentEntries: {},
    listTournamentsStatus: 'idle',
    createTournamentStatus: 'idle',
    updateTournamentStatus: 'idle',
    fetchTournamentStatus: 'idle',
    deleteTournamentStatus: 'idle',
    listTournamentEntriesStatus: 'idle',
    putTournamentEntryCustomization: 'idle',
    createTournamentEntryStatus : 'idle',
    scanTournamentStatus: 'idle',
    viewAttributes: {},
    showCreateTournamentDialog: false,
    showEditTournamentEntrySettingsDialog: false,
    showManageTournamentSectionsDialog: false,
    showEditTournamentDialog: false,
    showViewTournamentEntryDialog: false,
    showCustomizeTournamentEntryDialog: false,
    showAddTournamentEntryDialog: false,
    showMoveEntryToSectionDialog: false,
    showExportTournamentDialog: false,
    showImportTournamentResourceDialog: false,
    showViewAttributesDialog: false,
    tournamentToEdit: null,
    tournamentEntryToView: null,
    tournamentEntryToCustomize: null,
    tournamentToExport: null,
    tournamentToReplicate: null,
  },
  reducers: {
    setViewAttributes(state, action) {
      const { academyId, tournamentId, viewAttributes } = action.payload;
      state.viewAttributes[`${academyId}::${tournamentId}`] = viewAttributes;
      window.localStorage.setItem(`viewAttributes::${academyId}::${tournamentId}`, viewAttributes.join(','));
    },
    showCreateTournamentDialog(state, action) {
      state.showCreateTournamentDialog = true;
      state.createTournamentStatus = 'idle';
      state.tournamentToReplicate = action.payload.tournamentToReplicate || null;
    },
    hideCreateTournamentDialog(state) {
      state.showCreateTournamentDialog = false;
      state.tournamentToReplicate = null;
    },
    showEditTournamentDialog(state, action) {
      state.showEditTournamentDialog = true;
      state.updateTournamentStatus = 'idle';
      state.tournamentToEdit = action.payload;
    },
    hideEditTournamentDialog(state) {
      state.showEditTournamentDialog = false;
      state.tournamentToEdit = null;
    },
    showEditTournamentEntrySettingsDialog(state, action) {
      state.showEditTournamentEntrySettingsDialog = true;
      state.updateTournamentStatus = 'idle';
      state.tournamentToEdit = action.payload;
    },
    hideEditTournamentEntrySettingsDialog(state) {
      state.showEditTournamentEntrySettingsDialog = false;
    },
    showManageTournamentSectionsDialog(state, action) {
      state.showManageTournamentSectionsDialog = true;
      state.tournamentToEdit = action.payload;
    },
    hideManageTournamentSectionsDialog(state) {
      state.showManageTournamentSectionsDialog = false;
    },
    showViewTournamentEntryDialog(state, action) {
      state.showViewTournamentEntryDialog = true;
      state.tournamentEntryToView = action.payload;
    },
    hideViewTournamentEntryDialog(state) {
      state.showViewTournamentEntryDialog = false;
    },
    showCustomizeTournamentEntryDialog(state, action) {
      state.showCustomizeTournamentEntryDialog = true;
      state.tournamentEntryToCustomize = action.payload;
    },
    hideCustomizeTournamentEntryDialog(state) {
      state.showCustomizeTournamentEntryDialog = false;
    },
    showAddTournamentEntryDialog(state, action) {
      state.showAddTournamentEntryDialog = true;
      state.createTournamentEntryStatus = 'idle';
      state.tournamentToEdit = action.payload;
    },
    hideAddTournamentEntryDialog(state) {
      state.showAddTournamentEntryDialog = false;
    },
    showMoveEntryToSectionDialog(state, action) {
      state.showMoveEntryToSectionDialog = true;
      state.tournamentEntryToCustomize = action.payload;
    },
    hideMoveEntryToSectionDialog(state) {
      state.showMoveEntryToSectionDialog = false;
    },
    showExportTournamentDialog(state, action) {
      state.showExportTournamentDialog = true;
      state.tournamentToExport = action.payload;
    },
    hideExportTournamentDialog(state) {
      state.showExportTournamentDialog = false;
    },
    showImportTournamentResourceDialog(state, action) {
      state.showImportTournamentResourceDialog = true;
      state.tournamentToEdit = action.payload;
    },
    hideImportTournamentResourceDialog(state) {
      state.showImportTournamentResourceDialog = false;
    },
    showViewAttributesDialog(state, action) {
      state.showViewAttributesDialog = true;      
      state.tournamentToConfigure = action.payload;
    },
    hideViewAttributesDialog(state) {
      state.showViewAttributesDialog = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(listTournaments.pending, (state) => {
        state.listTournamentsStatus = 'loading'
      })
      .addCase(listTournaments.rejected, (state) => {
        state.listTournamentsStatus = 'failed'
      })
      .addCase(listTournaments.fulfilled, (state, action) => {
        state.listTournamentsStatus = 'succeeded'
        state.tournaments = action.payload.tournaments;
      })
      .addCase(createTournament.pending, (state) => {
        state.createTournamentStatus = 'loading'
      })
      .addCase(createTournament.rejected, (state) => {
        state.createTournamentStatus = 'failed'
      })
      .addCase(createTournament.fulfilled, (state, action) => {
        state.createTournamentStatus = 'succeeded'
        state.tournaments = state.tournaments.concat(action.payload)
      })
      .addCase(updateTournament.pending, (state) => {
        state.updateTournamentStatus = 'loading'
      })
      .addCase(updateTournament.rejected, (state) => {
        state.updateTournamentStatus = 'failed'
      })
      .addCase(updateTournament.fulfilled, (state, action) => {
        state.updateTournamentStatus = 'succeeded'
        state.tournaments = state.tournaments.map((tournament) => {
          const { academyId, tournamentId } = tournament;
          if (academyId === action.payload.academyId && tournamentId === action.payload.tournamentId) {
            return action.payload;
          } else {
            return tournament;
          }
        });
      })
      .addCase(fetchTournament.pending, (state) => {
        state.fetchTournamentStatus = 'loading'
      })
      .addCase(fetchTournament.rejected, (state) => {
        state.fetchTournamentStatus = 'failed'
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        state.fetchTournamentStatus = 'succeeded'
        state.tournaments = state.tournaments.map((tournament) => {
          const { academyId, tournamentId } = tournament;
          if (academyId === action.payload.academyId && tournamentId === action.payload.tournamentId) {
            return action.payload;
          } else {
            return tournament;
          }
        });
        const { academyId, tournamentId } = action.payload;
        
        const ratingSystems = (action.payload.ratingsSystems || []).reduce((a, c) => Object.assign(a, {[c]: true}), {});
        const defaultViewAttributes = [
          ratingSystems.uschess && 'uschess-id',
          ratingSystems.nwsrs && 'nwsrs-id',
        ].filter(v => !!v);
        state.viewAttributes[`${academyId}::${tournamentId}`] = (window.localStorage.getItem(`viewAttributes::${academyId}::${tournamentId}`) || defaultViewAttributes.join(',')).split(',');
      })
      .addCase(deleteTournament.fulfilled, (state, action) => {
        state.deleteTournamentStatus = 'succeeded'
        state.tournaments = state.tournaments.filter((tournament) => {
          const { academyId, tournamentId } = tournament;
          return academyId !== action.payload.academyId || tournamentId !== action.payload.tournamentId;
        });
      })
      .addCase(listTournamentEntries.pending, (state) => {
        state.listTournamentEntriesStatus = 'loading'
      })
      .addCase(listTournamentEntries.rejected, (state) => {
        state.listTournamentEntriesStatus = 'failed'
      })
      .addCase(listTournamentEntries.fulfilled, (state, action) => {
        state.listTournamentEntriesStatus = 'succeeded';
        state.tournamentEntries[`${action.payload.academyId}::${action.payload.tournamentId}`] = action.payload.entries;
      })
      .addCase(putTournamentEntryCustomization.pending, (state) => {
        state.putTournamentEntryCustomizationStatus = 'loading';
      })
      .addCase(putTournamentEntryCustomization.rejected, (state) => {
        state.putTournamentEntryCustomizationStatus = 'failed'
      })
      .addCase(putTournamentEntryCustomization.fulfilled, (state, action) => {
        state.putTournamentEntryCustomizationStatus = 'succeeded';
        const entriesKey = `${action.payload.academyId}::${action.payload.tournamentId}`;
        if (state.tournamentEntries[entriesKey]) {
          state.tournamentEntries[entriesKey] = state.tournamentEntries[entriesKey].map((entry) => {
            if (entry.entryId === action.payload.entryId) {
              return { ...entry, customization: action.payload.customization };
            }
            return entry;
          });
        }
      })
      .addCase(createTournamentEntry.pending, (state) => {
        state.createTournamentEntryStatus = 'loading';
      })
      .addCase(createTournamentEntry.rejected, (state) => {
        state.createTournamentEntryStatus = 'failed';
      })
      .addCase(createTournamentEntry.fulfilled, (state, action) => {
        state.createTournamentEntryStatus = 'succeeded';
        const entriesKey = `${action.payload.academyId}::${action.payload.tournamentId}`;
        if (state.tournamentEntries[entriesKey]) {
          state.tournamentEntries[entriesKey].push(action.payload.entry);
        }
      })
      .addCase(scanTournament.pending, (state) => {
        state.scanTournamentStatus = 'loading';
      })
      .addCase(scanTournament.rejected, (state) => {
        state.scanTournamentStatus = 'failed';
      })
      .addCase(scanTournament.fulfilled, (state) => {
        state.scanTournamentStatus = 'succeeded';
      })
  }
})

export default tournamentsSlice.reducer

export const {
  setViewAttributes,
  showCreateTournamentDialog,
  hideCreateTournamentDialog,
  showEditTournamentDialog,
  hideEditTournamentDialog,
  showEditTournamentEntrySettingsDialog,
  hideEditTournamentEntrySettingsDialog,
  showManageTournamentSectionsDialog,
  hideManageTournamentSectionsDialog,
  showViewTournamentEntryDialog,
  hideViewTournamentEntryDialog,
  showCustomizeTournamentEntryDialog,
  hideCustomizeTournamentEntryDialog,
  showAddTournamentEntryDialog,
  hideAddTournamentEntryDialog,
  showMoveEntryToSectionDialog,
  hideMoveEntryToSectionDialog,
  showExportTournamentDialog,
  hideExportTournamentDialog,
  showImportTournamentResourceDialog,
  hideImportTournamentResourceDialog,
  showViewAttributesDialog,
  hideViewAttributesDialog,
} = tournamentsSlice.actions;
